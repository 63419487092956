._control_container {
  .__controller_container__ {
    display: grid;
    grid-template-columns: 15% 85%;
    background-color: $color_green_four;
    height: 220px;

    .__controller_container_logo__ {
      display: grid;
      grid-template-columns: 100%;
      justify-self: center;
      align-self: center;

      .__controller__img {
        box-shadow: 1px 1px 4px 4px rgba($color_grey_principal, 0.3);
        border-radius: 15px;
      }
    }

    ._control_container_projects {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 80% 20%;
    }
  }

  ._control_container_controllers_all {
    padding-top: 30px;
    margin: 20px 30px 30px 30px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 20px;
    row-gap: 30px;
  }
}

@media (max-width: $screen_sslg) {
  ._control_container {
    .__controller_container__ {
      grid-template-columns: 20% 80%;
    }
  }
}

@media (max-width: $screen_slg) {
  ._control_container {
    .__controller_container__ {
      grid-template-columns: 22% 78%;
    }

    ._control_container_controllers_all {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media (max-width: $screen_md) {
  ._control_container {
    .__controller_container__ {
      grid-template-rows: 45% 55%;
      grid-template-columns: 100%;
      height: 550px;

      .__controller_container_logo__ img {
        width: 200px;
        height: 200px;
      }

      ._control_container_projects {
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;
      }
    }

    ._control_container_controllers_all {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: $screen_xs) {
  ._control_container {
    ._control_container_controllers_all {
      grid-template-columns: 100%;
    }
  }
}
