.__container_form {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-around;
  //   background-color: aqua;
}

.__avatar_container {
  margin-top: 10px;
  align-items: start;
  display: grid;
}

._avatar {
  width: 100px;
  height: 100px;
}
