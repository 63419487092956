._devices_container {
  display: grid;
  margin-bottom: 35px;

  ._label_offline {
    display: grid;
    background-color: $color_red_principal;
    height: 40px;

    ._label_offline_container {
      justify-self: center;
      align-self: center;

      ._icon_label_offline {
        color: $color_white_principal;
        padding-right: 10px;
        font-size: 14px;
      }

      ._title_label_offline {
        font-size: 14px;
        color: $color_white_principal;
      }
    }
  }

  .__devices_container_project_and_filter__ {
    display: grid;
    grid-template-columns: 15% 85%;
    background-color: $color_green_four;
    height: 220px;

    .__devices_container_logo__ {
      display: grid;
      grid-template-columns: 100%;
      justify-self: center;
      align-self: center;

      .__devices_img__ {
        box-shadow: 1px 1px 4px 4px rgba($color_grey_principal, 0.3);
        border-radius: 15px;
      }
    }

    .__devices_container_project_filters__ {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 60% 40%;

      .__devices_container_project__ {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }

      .__devices_container_filter__ {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: 10px 2% 10px 2%;
        margin: 10px 2% 20px 0%;
        background-color: $color_white_principal;
        border-radius: 15px;
        box-shadow: 1px 1px 4px 4px rgba($color_grey_principal, 0.3);

        .__devices_container_name__ {
          display: grid;
          grid-template-columns: 100%;

          .__devices_name__ {
            justify-self: flex-start;
            align-self: center;
            font-size: 18px;
            color: $color_black_principal;
            font-weight: 600;
            border-radius: 5px;
          }
        }

        .__devices_container_filter_option__ {
          display: grid;
          grid-template-columns: 100%;
          // margin: 0 20% 0 20%;

          .__devices_filter_status__ {
            display: flex;
            justify-content: space-evenly;
            gap: 10px;

            .__filter_status_title__ {
              justify-self: center;
              align-self: center;
            }

            %__filter_status_title__ {
              padding: 5px 25px 5px 25px;
              border-radius: 5px;
              font-size: 16px;
              cursor: pointer;
            }

            .__filter_status_title_all_true__ span {
              @extend %__filter_status_title__;
              background-color: $color_grey_four;
              color: $color_white_principal;

              &:hover {
                background-color: $color_grey_four;
                color: $color_white_principal;
              }
            }

            .__filter_status_title_all_false__ span {
              @extend %__filter_status_title__;
              background-color: $color_grey_secondary;

              &:hover {
                background-color: $color_grey_four;
                color: $color_white_principal;
              }
            }

            .__filter_status_title_on_true__ span {
              @extend %__filter_status_title__;
              background-color: $color_green_principal;
              color: $color_white_principal;

              &:hover {
                background-color: $color_green_principal;
                color: $color_white_principal;
              }
            }

            .__filter_status_title_on_false__ span {
              @extend %__filter_status_title__;
              background-color: $color_grey_secondary;

              &:hover {
                background-color: $color_green_principal;
                color: $color_white_principal;
              }
            }

            .__filter_status_title_off_true__ span {
              @extend %__filter_status_title__;
              background-color: $color_red_principal;
              color: $color_white_principal;

              &:hover {
                background-color: $color_red_principal;
                color: $color_white_principal;
              }
            }

            .__filter_status_title_off_false__ span {
              @extend %__filter_status_title__;
              background-color: $color_grey_secondary;

              &:hover {
                background-color: $color_red_principal;
                color: $color_white_principal;
              }
            }
          }
        }

        .__devices_container_report__ {
          display: grid;
          grid-template-columns: 100%;

          ._devices_report {
            display: grid;
            grid-template-columns: 80% 20%;
            column-gap: 5px;
            justify-self: center;
            align-self: center;

            ._report_select {
              justify-self: center;
              align-self: flex-start;

              ._reprot_select_option {
                font-size: 18px;
                padding: 5px 35px 5px 35px;
                margin-left: 5px;
                background-color: rgba(gray, 0.1);
                border: none;
                width: 100%;
                border-radius: 15px;
              }
            }

            ._report_ver_container {
              justify-self: flex-start;
              align-self: flex-start;

              ._report_ver {
                border: none;
                font-size: 25px;
                padding: 3px 10px 5px 10px;
                border-radius: 5px;
                color: $color_green_principal;
                background-color: white;
                cursor: pointer;
              }
            }
          }
        }

        .add_device_button {
          display: flex;
          align-self: center;
          align-items: center;
          &:hover {
            cursor: pointer;
          }
        }

        .__devices_container_count_devices__ {
          display: grid;
          grid-template-columns: 100%;

          ._count_container {
            justify-self: center;
            align-self: center;
            font-size: 18px;

            ._count_devices_fails {
              color: $color_red_principal;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  ._container_devices_all {
    display: grid;
    padding-top: 30px;
    margin: 0 2% 0 2%;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
    row-gap: 10px;

    ._container_new_devices_all {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: repeat(2, 1fr);
      row-gap: 10px;
    }
  }
}

@media (max-width: $screen_sslg) {
  ._devices_container {
    .__devices_container_project_and_filter__ {
      grid-template-columns: 20% 80%;

      .__devices_container_project_filters__ {
        .__devices_container_filter__ {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          margin: 10px 2% 10px 2%;

          .__devices_container_filter_option__ {
            // margin: 5px 5% 5px 5%;

            .__devices_filter_status__ {
              .__filter_status_title__ span {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    ._container_devices_all {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media (max-width: $screen_slg) {
  ._devices_container {
    .__devices_container_project_and_filter__ {
      grid-template-columns: 100%;
      height: 440px;

      .__devices_container_project_filters__ {
        .__devices_container_filter__ {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          margin: 10px 2% 10px 2%;

          .__devices_container_filter_option__ {
            .__devices_filter_status__ {
              .__filter_status_title__ span {
                padding: 5px 15px 5px 15px;
                font-size: 10px;
              }
            }
          }
        }
      }
    }

    ._container_devices_all {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: $screen_lg) {
  ._devices_container {
    .__devices_container_project_and_filter__ {
      grid-template-columns: 100%;
      height: 440px;
    }

    ._container_devices_all {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: $screen_md) {
  ._devices_container {
    .__devices_container_project_and_filter__ {
      grid-template-rows: 40% 60%;
      height: 850px;

      .__devices_container_project_filters__ {
        grid-template-rows: repeat(2, 1fr);

        .__devices_container_filter__ {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          margin: 10px 2% 10px 2%;

          .__devices_container_name__ {
            .__devices_name__ {
              padding-left: 0;
              justify-self: center;
            }
          }
          .__devices_container_report__ {
            ._devices_report {
              column-gap: 0px;
              ._report_select {
                ._reprot_select_option {
                  padding: 5px 5px 5px 25px;
                }
              }
            }
          }
        }

        .__devices_container_project__ {
          grid-template-columns: 100%;
        }
      }
    }

    ._container_devices_all {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: $screen_xs) {
  ._devices_container {
    ._container_devices_all {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
