._areas_container {
  .__areas_container__ {
    display: grid;
    grid-template-columns: 15% 85%;
    background-color: $color_green_four;
    height: 220px;

    .__areas_container_logo__ {
      display: grid;
      grid-template-columns: 100%;
      justify-self: center;
      align-self: center;

      .__areas__img {
        box-shadow: 1px 1px 4px 4px rgba($color_grey_principal, 0.3);
        border-radius: 15px;
      }
    }

    .__areas_container_project_and_filters__ {
      display: grid;
      grid-template-rows: 55% 45%;

      .__areas_container_project__ {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }

      .__areas_container_filters__ {
        display: grid;
        grid-template-columns: 70% 30%;
        margin: 10px 2% 20px 0%;
        border-radius: 15px;
        column-gap: 30px;

        %__open_drop__ {
          display: block;
          position: absolute;
          z-index: 1;
          width: 250px;
          border-radius: 10px;
          background-color: #f5f5f5;
          box-shadow: 1px 1px 4px 4px rgba($color_grey_principal, 0.3);
          border: 1px solid rgba($color_grey_principal, 0.7);
        }

        .__areas_container_name_filters_and_search__ {
          display: grid;
          grid-template-columns: auto 30% auto auto;
          border-radius: 15px;
          background-color: $color_white_principal;
          box-shadow: 1px 1px 4px 4px rgba($color_grey_principal, 0.3);

          .__area_container_filters_name__ {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            font-size: 20px;
            color: $color_grey_four;
            font-weight: 600;
          }

          .__area_container_filters_status__ {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            box-shadow: 1px 1px 4px 4px rgba($color_grey_principal, 0.3);
            margin: 15px 5px;
            padding: 5px 0px;
            border-radius: 5px;

            .__area_container_text_filter__ {
              text-align: center;
            }

            .__area_container_icon_filter__ {
              justify-self: flex-start;
              align-self: center;
              padding: 5px 12px;
              border-radius: 50%;
              cursor: pointer;
              background-color: rgba($color_grey_principal, 0.3);

              &:hover {
                background-color: rgba($color_grey_principal, 0.5);
              }
            }
          }

          .__open_drop_filter_close__ {
            display: none;
          }

          .__open_drop_filter_open__ {
            @extend %__open_drop__;
            margin-left: 150px;
            margin-top: 70px;
          }

          .__filters_names__ {
            border-radius: 5px;
            font-size: 17px;

            .__filter_name_todas__ {
              padding: 10px 25px;
              border-radius: 10px 10px 0 0;
              color: $color_black_principal;
              cursor: pointer;

              &:hover {
                color: $color_white_principal;
                background-color: $color_grey_four;
              }
            }

            .__filter_name_libre__ {
              padding: 10px 25px;
              cursor: pointer;

              &:hover {
                color: $color_white_principal;
                background-color: $color_green_principal;
              }
            }

            .__filter_name_ocupado__ {
              padding: 10px 25px;
              cursor: pointer;

              &:hover {
                color: $color_white_principal;
                background-color: $color_blue_three;
              }
            }

            .__filter_name_fuera__ {
              padding: 10px 25px;
              cursor: pointer;

              &:hover {
                color: $color_white_principal;
                background-color: $color_red_principal;
              }
            }

            .__filter_name_sin__ {
              padding: 10px 25px;
              cursor: pointer;
              border-radius: 0 0 10px 10px;

              &:hover {
                color: $color_white_principal;
                background-color: $color_grey_principal;
              }
            }
          }

          .__area_container_filters_search__ {
            display: grid;
            grid-template-columns: 100%;
            justify-self: center;
            align-self: center;

            ._areas_container_search_input input {
              box-shadow: 1px 1px 4px 4px rgba($color_grey_principal, 0.3);
              border-radius: 5px;
              background-color: white;
              padding: 10px 15px 10px 15px;
              border: none;
              font-size: 15px;
            }
          }

          .add_area_button {
            display: flex;
            align-self: center;
            align-items: center;
          }
        }

        .__areas_container_filter_report__ {
          display: grid;
          border-radius: 15px;
          margin-right: 40px;
          background-color: $color_white_principal;
          box-shadow: 1px 1px 4px 4px rgba($color_grey_principal, 0.3);

          ._areas_report {
            display: flex;
            justify-content: center;

            ._report_ver_container {
              display: flex;
              justify-self: center;
              align-self: center;
              box-shadow: 1px 1px 4px 4px rgba($color_grey_principal, 0.3);
              border: 1px solid rgba($color_grey_principal, 0.5);
              border-radius: 5px;
              padding: 10px 15px;

              ._report_ver {
                background-color: white;
                width: 150px;
                border: none;
                cursor: pointer;
                color: $color_black_principal;
                font-size: 17px;

                span {
                  padding-left: 5px;
                }
              }

              .__area_container_icon_report__ {
                justify-self: flex-start;
                align-self: center;
                padding: 5px 12px;
                border-radius: 50%;
                cursor: pointer;
                background-color: rgba($color_grey_principal, 0.3);

                &:hover {
                  background-color: rgba($color_grey_principal, 0.5);
                }
              }
            }
          }

          .__open_drop_report_close__ {
            display: none;
          }

          .__open_drop_report_open__ {
            @extend %__open_drop__;
            justify-self: center;
            margin-top: 70px;
          }

          .__buttons_names__ {
            font-size: 17px;
            border-radius: 5px;

            .__button_ocupation__ {
              padding: 10px 25px;
              border-radius: 10px 10px 0 0;
              color: $color_black_principal;
              cursor: pointer;

              &:hover {
                color: $color_white_principal;
                background-color: $color_grey_principal;
              }

              i {
                padding-right: 10px;
              }
            }

            .__button_medidores__ {
              padding: 10px 25px;
              color: $color_black_principal;
              cursor: pointer;

              i {
                padding-right: 10px;
              }

              &:hover {
                color: $color_white_principal;
                background-color: $color_grey_principal;
              }
            }

            .__button_report_fails_by_project__ {
              padding: 10px 25px;
              border-radius: 0 0 10px 10px;
              color: $color_black_principal;
              cursor: pointer;

              i {
                padding-right: 10px;
              }

              &:hover {
                color: $color_white_principal;
                background-color: $color_grey_principal;
              }
            }
          }
        }
      }
    }
  }

  ._areas_container_location {
    padding: 40px 2% 0 2%;
    margin-bottom: 35px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    row-gap: 20px;
    column-gap: 20px;
  }
}

@media (max-width: $screen_sslg) {
  ._areas_container {
    overflow: hidden;
    margin-bottom: 30px;

    .__areas_container__ {
      grid-template-columns: 18% 82%;

      .__areas_container_project_and_filters__ {
        .__areas_container_filters__ {
          grid-template-columns: 70% 30%;

          .__areas_container_name_filters_and_search__ {
            .__open_drop_filter_open__ {
              margin-left: 150px;
            }
          }

          .__area_container_filters_search__ {
            ._areas_container_search_input {
              padding: 10px;
            }
          }
        }
      }
    }

    ._areas_container_location {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}

@media (max-width: $screen_slg) {
  ._areas_container {
    overflow: hidden;

    .__areas_container__ {
      grid-template-columns: 100%;
      height: 440px;

      .__areas_container_project_and_filters__ {
        .__areas_container_filters__ {
          margin: 10px 0px 10px 2%;
          grid-template-columns: 70% 30%;
          column-gap: 20px;

          .__areas_container_name_filters_and_search__ {
            .__open_drop_filter_open__ {
              margin-left: 110px;
            }
          }

          .__area_container_filters_name__ {
            padding-left: 0%;
          }
        }
      }
    }

    ._areas_container_location {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

@media (max-width: $screen_lg) {
  ._areas_container {
    overflow: hidden;

    ._areas_container_location {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media (max-width: $screen_md) {
  ._areas_container {
    overflow: hidden;

    .__areas_container__ {
      height: 800px;

      .__areas_container_logo__ img {
        width: 250px;
        height: 250px;
      }

      .__areas_container_project_and_filters__ {
        .__areas_container_project__ {
          grid-template-columns: 100%;
        }

        .__areas_container_filters__ {
          grid-template-columns: 100%;
          row-gap: 15px;
          // margin: 15px 10px 15px 10px;

          .__areas_container_name_filters_and_search__ {
            width: 98%;
            height: 70px;

            .__open_drop_filter_open__ {
              margin-left: 150px;
              margin-top: 70px;
            }
          }

          .__areas_container_filter_report__ {
            display: flex;
            justify-content: center;
            height: 70px;
            width: 98%;
            margin-right: 0px;
          }

          .__area_container_filters_status__ {
            grid-template-columns: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: $screen_xmd) {
  ._areas_container {
    overflow: hidden;

    .__areas_container__ {
      height: 980px;

      .__areas_container_logo__ img {
        width: 180px;
        height: 180px;
      }

      .__areas_container_project_and_filters__ {
        .__areas_container_filters__ {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          // margin-right: 20px;
          // grid-template-rows: 55% 45%;

          .__areas_container_name_filters_and_search__ {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: auto;
            width: 80%;

            .__open_drop_filter_open__ {
              margin-left: 80px;
            }

            .__area_container_filters_status__ {
              display: flex;
              justify-content: center;
              width: 40%;
              gap: 15px;
            }
          }

          .__areas_container_filter_report__ {
            width: 80%;
          }
        }
      }
    }

    ._areas_container_location {
      grid-template-columns: repeat(3, 1fr);
      margin: 0 10px 0 10px;
    }
  }
}

@media (max-width: $screen_xs) {
  ._areas_container {
    overflow: hidden;

    .__areas_container__ {
      .__areas_container_project_and_filters__ {
        .__areas_container_filters__ {
          margin: 5px 2% 5px 2%;

          .__areas_container_name_filters_and_search__ {
            .__open_drop_filter_open__ {
              margin-left: 20px;
              margin-top: 100px;
            }

            .__area_container_filters_status__ {
              width: 57%;
            }

            @media (max-width: 400px) {
              .__area_container_filters_status__ {
                width: 75%;
              }
            }
          }
        }
      }
    }

    ._areas_container_location {
      grid-template-columns: repeat(2, 1fr);
      margin: 0 10px 0 10px;
    }
  }
}

@media (max-width: $screen_xxs) {
  ._areas_container {
    overflow: hidden;

    ._areas_container_location {
      grid-template-columns: repeat(2, 1fr);
      margin: 0 10px 0 10px;
    }
  }
}
