// convention name class
// ProjectCard = _pro_car 

._pro_car_container {
    display: grid;
    height: 200px;
    border-radius: 10px;
    box-shadow: 4px 1px 4px 1px rgba(grey, 0.3);
    border: rgba($color: $color_grey_principal, $alpha: 0.3) 1px solid;
    grid-template-columns: 20% 30% 30% 20%;
    cursor: pointer;

    ._pro_car_container_img {
        display: grid;
        grid-template-columns: 100%;

        ._pro_car_img_container {
            display: grid;
            grid-template-columns: 100%;
            height: 200px;

            ._pro_car_img {
                width: 150px;
                height: 150px;
                border-radius: 10px;
                align-self: center;
                justify-self: center;
            }
        }
    }

    ._pro_car_container_info {
        display: grid;
        grid-template-rows: repeat(3, 1fr);

        ._pro_car_info_name {
            grid-template-columns: 100%;
            font-size: 18px;
            color: black;
            font-weight: 600;
            align-self: flex-end;
        }

        ._pro_car_info_state {
            display: grid;
            grid-template-columns: 100%;
            
            ._pro_car_info_state_color {
                padding: 5px 15px 5px 15px;
                font-size: 18px;
                justify-self: flex-start;
                align-self: center;
                font-weight: bold;
                color: white;
            }

            .color_percent_0 {
                border-radius: 6px;
                background-color: $color_onoff_0;
            }

            .color_percent_25 {
                border-radius: 6px;
                background-color: $color_onoff_25;
            }
            
            .color_percent_50 {
                border-radius: 6px;
                background-color: $color_onoff_50;
            }
            
            .color_percent_75 {
                border-radius: 6px;
                background-color: $color_onoff_75;
            }
            
            .color_percent_100 {
                border-radius: 6px;
                background-color: $color_onoff_100;
            }
        }

        ._pro_car_info_city_contry {
            font-size: 18px;
            justify-self: start;
            align-self: flex-start;
        }
    }

    ._pro_car_container_cad {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);

        ._pro_car_info_count_titles {
            justify-self: center;
            align-self: flex-end;
            font-size: 15px;
            color: $color_black_principal;
        }

        ._pro_car_info_num_count {
            font-size: 17px;
            justify-self: center;
            align-self: flex-start;
            color: $color_black_principal;

            ._pro_car_info_num_count_off {
                color: $color_red_principal;
                font-weight: 600;
            }
        }
    }

    ._pro_car_container_weather {
        display: grid;
        grid-template-columns: 100%;

        ._pro_car_info_weather_and_clocl {
            display: grid;
            grid-template-rows: 60% 40%;

            ._pro_car_info_clock_ {
                justify-self: center;
                align-self: flex-start;
                font-size: 17px;
                color: $color_green_principal;
            }

            ._pro_car_info_weather_ {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                color: $color_grey_four;
                
                ._pro_car_info_weather_icon_ {
                    justify-self: flex-end;
                    align-self: flex-end;
                    font-size: 25px;
                }

                ._pro_car_info_weather_temp_ {
                    padding-bottom: 20px;
                    justify-self: flex-start;
                    align-self: flex-end;
                    font-size: 25px;
                }
            }
        }
    }
}

@media (max-width: 1080px){
    ._pro_car_container {
        height: 530px;
        grid-template-columns: 100%;

        ._pro_car_container_info {
            ._pro_car_info_name {
                justify-self: center;
            }
    
            ._pro_car_info_state {
                ._pro_car_info_state_color {
                    justify-self: center;  
                }
            }
    
            ._pro_car_info_city_contry {
                justify-self: center;
            }
        }

        ._pro_car_container_cad {    
            ._pro_car_info_count_titles {
                font-size: 15px;
            }
    
            ._pro_car_info_num_count {
                font-size: 18px;
            }
        }

        ._pro_car_container_weather {
            margin-bottom: 15px;
        }
    }
}