%ext_items_device_areas {
    justify-self: center;
    font-size: 14px;
    font-weight: 600;
} 

.__control_container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 42% 40% 20%;
    border-radius: 15px;
    box-shadow: 1px 2px 1px 1px rgba(gray, 0.3);
    border: 1px solid $color_grey_secondary;
    height: 130px;

    .__controller_edit {
        position: absolute;
        justify-self: end;
        padding-top: 4px;
        padding-right: 6px;
        align-self: self-start;
        font-size: 20px;
        color: #d3d3d3;
    }
    
    .__controller_edit:hover {
        color: $color_white_principal; 
        cursor: pointer;
    }

    .__control_container_title {
        display: grid;
        grid-template-rows:repeat(2, 1fr);
        border-radius: 10px 10px 0 0;

        .__control_title {
            justify-self: center;
            align-self: flex-start;
            font-size: 12px;
            color: $color_white_principal;
        }

        .__control_serial {
            font-size: 13px;
            justify-self: center;
            align-self: center;
            font-weight: 500;
            color: $color_white_principal;
        }
    }

    .__controller_on {
        background-color: $color_green_principal;
    }

    .__controller_off {
        background-color: $color_red_principal;
    }

    .__control_container_device_areas {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: repeat(2, 50%);

        .__control_items_name {
            @extend %ext_items_device_areas;
            align-self: flex-end;
        }

        .__control_items_device_areas {
            @extend %ext_items_device_areas;
            align-self: flex-start;
        }
    }

    .__control_container_last_update {
        display: grid;
        grid-template-columns: 100%;
        background-color: $color_darkblue_one;
        border-radius: 0 0 15px 15px;

        .__control_last_update {
            color: $color_white_principal;
            justify-self: center;
            font-size: 13px;
            align-self: center;
            font-weight: 500;
        }
    }
}