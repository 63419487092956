.header_modal {
  display: flex;
  justify-content: space-between;
  background-color: $color_green_principal;
  color: $color_white_principal;
}

._close_modal_doorlock {
  cursor: pointer;

  i {
    border-radius: 50%;
    padding: 10px 15px;
    color: $color_white_principal;

    &:hover {
      background-color: rgba($color: $color_green_secondary, $alpha: 0.2);
    }
  }
}

.tablelistcodes_container {
  margin-bottom: 40px;
  

  .tablelistcodes_content_header {
    display: grid;
    margin-top: 20px;
    margin-bottom: 40px;
    grid-template-columns: repeat(4, 1fr);

    .__content_header_title {
      justify-self: flex-start;
      align-self: center;
      padding-left: 32px;
      font-family: $font_principal;

      h1 {
        font-weight: 900;
        font-size: 24px;
        margin: 0 0 10px 0;
        color: $color_black_principal;
      }

      span {
        font-weight: 600;
        font-size: 16px;
        color: $color_grey_principal;
      }
    }

    .__content_header_filters {
      display: grid;
      .__content_header_filters_container {
        justify-self: center;
        align-self: center;
        .__content_header_filters__title {
          font-size: 15px;
          color: $color_black_principal;
          font-weight: 600;
          font-family: $font_principal;
        }

        .__content_header_filters_input {
          padding: 5px 10px;
          font-size: 15px;
          border-radius: 5px;
          font-family: $font_principal;
          border: 1px solid $color_grey_secondary;
        }
      }
    }

    .__content_header_filters_status {
      display: grid;

      .__content_header_filters_container_status {
        justify-self: center;
        align-self: center;

        .__content_header_filters_status_title {
          font-size: 15px;
          color: $color_black_principal;
          font-weight: 600;
          font-family: $font_principal;
        }

        .__content_header_filters_status_input {
          padding: 5px 10px;
          font-size: 15px;
          border-radius: 5px;
          font-family: $font_principal;
          border: 1px solid $color_grey_secondary;
        }
      }
    }

    .__content_header_button {
      justify-self: flex-end;
      align-self: center;
      padding-right: 32px;

      button {
        background-color: $color_blue_three;
        font-family: $font_principal;
        color: $color_white_principal;
        box-shadow: 4px 2px 4px 2px rgba($color_grey_principal, 0.4);
        padding: 5px 15px;
        border-radius: 5px;
        font-size: 14px;
        border: none;
        cursor: pointer;

        i {
          padding-right: 5px;
        }
      }
    }
  }

  %extende_tablelistcodes_content_header {
    display: grid;
    grid-template-columns: 10% 25% 30% 15% 10% 10%;
    margin: 0 2%;
  }

  .tablelistcodes_content_header_table {
    @extend %extende_tablelistcodes_content_header;
    background-color: rgba($color_blue_three, 0.2);
    border-radius: 10px 10px 0 0;
    height: 80px;

    span {
      justify-self: flex-start;
      align-self: center;
      padding-left: 20px;
      font-family: $font_principal;
      color: $color_grey_four;
      font-weight: 900;
    }
  }

  .tablelistcodes_content_header_codes {
    @extend %extende_tablelistcodes_content_header;
    height: 80px;
    border-radius: 5px;

    .__div__ {
      justify-self: flex-start;
      padding-left: 20px;
      align-self: center;
      font-family: $font_principal;
      font-weight: 600;
      color: $color_grey_four;
    }

    .__content_codes_name_pass {
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      color: $color_grey_four;
      font-family: $font_principal;
      padding-left: 20px;

      .___content_name {
        font-size: 18px;
        font-weight: 600;
        align-self: flex-end;
      }

      .___content_pin {
        font-size: 12px;
        font-weight: 200;
      }
    }

    .__content_code_add_restriction {
      justify-self: flex-start;
      padding-left: 20px;
      align-self: center;
      font-family: $font_principal;
      font-weight: 600;
      color: $color_grey_four;

      .___content_add_text {
        justify-self: center;
        align-self: center;
        font-family: $font_principal;
        font-weight: 600;
      }

      .__content_range_restriction {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 50px;

        .___content_range_content {
          display: grid;
          grid-template-rows: repeat(3, 1fr);

          .____title_range_restrict {
            justify-self: center;
            align-self: center;
            font-size: 12px;
            color: $color_grey_four;
            font-weight: 200;
          }

          .____date_range_restrict {
            justify-self: center;
            align-self: center;
          }

          .____hour_range_restrict {
            font-size: 14px;
            align-self: center;
            justify-self: center;
            font-weight: 400;
          }
        }
      }
    }

    .__content_codes {
      // height: 50px;
    }
  }

  .tablelistcodes_content_header_codes_bg {
    background-color: rgba($color: $color_grey_secondary, $alpha: 0.2);
  }

  %extend_codes_status {
    justify-self: flex-start;
    font-size: 14px;
    align-self: center;
    font-weight: 700;
    margin-left: 20px;
    padding: 2px 3px 5px 3px;
    border-radius: 5px;
  }

  .__codes_status_activa {
    @extend %extend_codes_status;
    background-color: rgba($color: #22a95c, $alpha: 0.15);
    border: 1px solid #22a95c;
    color: #22a95c;
  }

  .__codes_status_inactiva {
    @extend %extend_codes_status;
    background-color: rgba($color_red_principal, 0.15);
    border: 1px solid $color_red_principal;
    color: $color_red_principal;
  }

  .__acciones_codelist {
    @extend %extend_codes_status;
    cursor: pointer;
  }
}
