._ReportFailsByProjec_container_ {
  display: flex;
  align-items: center;
  width: auto;
  padding: 15px;
  // box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
  //   rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
  //   rgba(0, 0, 0, 0.09) 0px 16px 8px;
  background-color: #3f9948;

  ._ReportFailsByProjec_container_close_ {
    display: flex;
    justify-content: center;
    width: 5%;
    font-size: 20px;
    color: white;
    cursor: pointer;
  }

  ._ReportFailsByProjec_container_name_ {
    font-size: 21px;
    color: white;
    font-family: $font_principal;
  }

  ._ReportFailsByProjec_container_download_ {
    justify-self: flex-end;
    padding-right: 25px;
    align-self: center;
    font-size: 20px;
    cursor: pointer;
    color: white;

    span {
      i {
        padding-left: 5px;
      }
    }
  }
  @media (max-width: $screen_xmd) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    padding: 15px;
    background-color: #3f9948;
    ._ReportFailsByProjec_container_name_ {
      font-size: 18px;
    }
    ._ReportFailsByProjec_container_close_ {
      font-size: 18px;
    }
  }
  @media (max-width: $screen_xsmd) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    padding: 15px;
    gap: 20px;
    background-color: #3f9948;
    ._ReportFailsByProjec_container_name_ {
      text-align: center;
      font-size: 13px;
    }
    ._ReportFailsByProjec_container_close_ {
      font-size: 15px;
    }
  }
}
