// Styles globals
@import 'global/_colors.scss';

//Style Principal
@import 'principal/_principal.scss';

// Styles Login
@import 'login/_login.scss';

// Style projects
@import 'projects/_projects.scss';
@import 'projects/_projectCard.scss';
@import 'projects/projectCardAreaControl';
@import 'projects/createProject';
@import 'projects/dataTableProjects';

// Style areas
@import 'areas/areas.scss';
@import 'areas/areaCard.scss';
@import '../components/areaSelector/areaSelector.scss';

// Styles Controllers
@import 'controllers/_controllerCard.scss';
@import 'controllers/_controllers.scss';

//Styles Devices
@import 'devices/device.scss';
@import 'devices/devices.scss';
@import 'devices/hsc.scss';
@import 'devices/weather.scss';
@import 'devices/pluginMotel';
@import 'devices/header';
@import 'devices/new_thermosthat'; //Styles NewDevices
@import 'newDevices/newDevices.scss';
@import 'devices/vrf.scss'; //Style FilterProject
@import 'filterProject/filterProject.scss';
@import 'devices/newDevices';
@import '../views/private/devicesManagement/devicesManagement/devicesManagement.scss';

//Style SideBar
@import 'sideBar/sideBar.scss';

//Style Reports
@import 'reports/ocupation.scss';
@import 'reports/measurer.scss';
@import 'reports/temperature.scss';
@import './reports/measurerGeneral';
@import './reports/batteryDevices';
@import './reports//DeviseFailsByProject';
@import './reports/reportFailsByProyect';
@import './reports/new_report';

//Styles Measurer
@import './measurer/measurer.scss';
@import './measurer/measurerCard.scss';
@import './measurer/measurerDetails.scss';
@import './measurer/cardMeasurers.scss';
@import './measurer/totalMeasurers.scss';

//Styles DateCustom
@import './hooks/dateCustom.scss';
@import '../components/reactDateRange/style/ReactDateRange.scss';

//Style DirectAccess
@import './hooks/DirectAccess.scss';

//Styles Forgot Password
@import './forgotPassword/forgotPassword.scss';

//Styles Reset Password
@import './resetPassword/resetPassword.scss';
@import './resetPassword/confirmation.scss';

// Style Doorlock
@import './doorlock/tableListCodes.scss';

//Style Roles
@import './roles/componentsPermissions.scss';
@import './tableRoles/_rolesDataTable';
@import './permissionsRole/_permissionsRole';

@import '../styles/create_projects/_form_projects';

// Style UserTable
@import './user/usersManagements';
@import './devices/graphBattery';

@font-face {
  font-family: 'Open24';
  src: local('Open24'), url(./global/Open24.woff) format('woff');
}