.__DeviceFailsByProjectContainer__ {
  ._empty_data_ {
    margin-top: 300px;
    text-align: center;
    font-size: 30px;
    margin-bottom: 250px;
  }

  .__DFPC_Title_Filter__ {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 45px;
    margin-top: 50px;
    margin-bottom: 20px;

    .__DFPC_Title__ {
      justify-self: center;
      align-self: center;
      font-size: 20px;
      color: $color_black_principal;
      font-family: $font_principal;
    }

    .__DFPC_Select__ {
      justify-self: center;
      color: $color_black_principal;
      align-self: center;

      label {
        font-size: 20px;
        margin-right: 10px;
        font-family: $font_principal;
      }

      select {
        width: 200px;
        font-size: 20px;
      }
    }
  }

  .__DFPC_DeviceFailsContainer__ {
    display: grid;
    margin: 0 150px;

    .__DFPC_DF_content__ {
      display: grid;
      margin: 20px 0px;
      border: 1px solid rgba($color_grey_terceary, 0.4);

      .__place__ {
        justify-self: flex-start;
        align-self: center;
        grid-template-columns: 100%;
        color: $color_black_principal;
        font-family: $font_principal;
        font-size: 15px;
        font-weight: 600;
        margin-left: 12px;
        margin-bottom: 15px;
        margin-top: 15px;
      }

      .__DFPC_DF_container_devices__ {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 15px;
        row-gap: 15px;
        margin: 10px;

        .__info__ {
          display: grid;
          grid-template-columns: 30% 70%;
          border: 1px solid rgba($color_grey_terceary, 0.2);
          height: 90px;

          img {
            width: 35px;
            height: 35px;
            justify-self: center;
            align-self: center;
            padding: 5px;
          }

          .__description_content__ {
            display: grid;
            grid-template-rows: repeat(3, 1fr);
            padding: 5px;

            .__title_category__ {
              font-size: 12px;
              justify-self: center;
              align-self: center;
              font-family: $font_principal;
              &::first-letter {
                text-transform: capitalize;
              }
            }

            .__title_description__ {
              font-size: 15px;
              justify-self: center;
              align-self: center;
              font-family: $font_principal;
            }

            .__BaterryLevel_on__ {
              justify-self: center;
              align-self: center;
              font-size: 12px;

              .battery_device_level {
                display: flex;

                strong {
                  align-self: center;
                  margin-right: 10px;
                }

                .battery_device_averange {
                  justify-self: center;
                  align-self: center;
                }

                .battery_device_level_icon {
                  margin-left: 2px;
                  justify-self: center;
                  align-self: center;
                  transform: rotate(90deg);
                }

                .color_battery_100 {
                  color: $color_onoff_100;
                }

                .color_battery_30-10 {
                  color: $color_onoff_75;
                }

                .color_battery_10-0 {
                  color: $color_onoff_0;
                }
              }
            }

            .__date_offline__ {
              font-size: 12px;
              color: $color_red_principal;
              font-weight: 700;
              justify-self: center;
              align-self: flex-start;
              font-family: $font_principal;

              span {
                color: black;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: $screen_xlg) {
    .__DFPC_Title_Filter__ {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      gap: 40px;
      margin-top: 50px;
      margin-bottom: 20px;
    }

    .__DFPC_DeviceFailsContainer__ {
      .__DFPC_DF_content__ {
        .__place__ {
          display: flex;
          align-items: center;
          justify-content: center;
          justify-self: unset;
          align-self: unset;
        }
        .__DFPC_DF_container_devices__ {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          column-gap: 15px;
          row-gap: 15px;
          margin: 10px;
        }
      }
    }
  }
  @media (max-width: $screen_xmd) {
    .__DFPC_Title_Filter__ {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      gap: 30px;
      margin-top: 40px;
      margin-bottom: 20px;
      .__DFPC_Title__ {
        font-size: 18px;
      }
      .__DFPC_Select__ {
        label {
          font-size: 18px;
        }
        select {
          font-size: 18px;
        }
      }
    }
    .__DFPC_DeviceFailsContainer__ {
      display: grid;
      margin: 0 70px;
    }
  }
  @media (max-width: $screen_xs) {
    .__DFPC_Title_Filter__ {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      gap: 30px;
      margin-top: 40px;
      margin-bottom: 20px;
      .__DFPC_Title__ {
        font-size: 16px;
      }
      .__DFPC_Select__ {
        label {
          font-size: 16px;
        }
        select {
          font-size: 16px;
        }
      }
    }
  }

  @media (max-width: $screen_xsmd) {
    .__DFPC_DeviceFailsContainer__ {
      display: grid;
      margin: 0 20px;

      .__DFPC_DF_content__ {
        .__place__ {
          margin-left: 0%;
          margin-bottom: 5px;
          font-size: 12px;
        }
        .__DFPC_DF_container_devices__ {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          column-gap: 15px;
          row-gap: 15px;
          margin: 10px;
          .__info__ {
            display: flex;
            flex-direction: column;
            border: 1px solid rgba($color_grey_terceary, 0.2);
            height: auto;
            .__description_content__ {
              gap: 5px;
              .__title_category__ {
                text-align: center;
              }
              .__title_description__ {
                font-size: 12px;
              }
              .__date_offline__ {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
