.__pluginMotel_principal_ {
  display: grid;
  box-shadow: 1px 2px 1px 1px rgba(grey, 0.3);
  border: rgba($color: $color_grey_principal, $alpha: 0.3) 1px solid;
  height: 300px;
  border-radius: 15px;
  grid-template-rows: 15% 30% 10% 45%;

  .__title_pluginMotel {
    display: grid;
    grid-template-columns: 70% 30%;
    background-color: rgba($color: gray, $alpha: 0.1);
    border-radius: 15px 15px 0px 0px;

    .__title__ {
      justify-self: flex-start;
      align-self: center;
      padding-left: 12px;
      font-size: 17px;
    }
  }

  .__content_pluginMotel {
    display: grid;
    grid-template-columns: 35% 65%;

    .__img_logo_pluginMotel {
      justify-self: center;
      align-self: center;
    }

    .__content_state_pluginMotel {
      display: grid;
      grid-template-rows: 40% 60%;

      .__title_text_state {
        justify-self: center;
        align-self: center;
        font-size: 15px;
        font-weight: bold;
        color: $color_grey_principal;
      }

      .__state_actual_pluginMotel {
        margin: 0 10px 10px 10px;
        padding-top: 8px;
        text-align: center;
        font-weight: 600;
        font-size: 17px;
        color: $color_white_principal;
        border-radius: 5px;
      }
    }
  }

  .__other_states_pluginMotel__ {
    text-align: center;
    font-weight: 600;
    color: $color_grey_principal;
  }

  .__content_states_pluginMotel {
    display: grid;
    margin: 0 15px 15px 15px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);

    .__item_states_pluginMotel {
      font-size: 16px;
      width: 70px;
      border: 1px solid rgba($color: $color_grey_principal, $alpha: 0.3);
      background-color: rgba($color: $color_grey_principal, $alpha: 0.1);
      border-radius: 5px;
      text-align: center;
      padding: 10px 15%;
      margin: auto;
    }
  }
}
