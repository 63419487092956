.user_datatable_container {
  display: grid;
  max-width: 1536px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  .user_container_header {
    display: flex;
    height: 100%;
    width: auto;
    font-size: 12px;

    h1 {
      margin-left: 3%;
    }

    // span {
    //   font-weight: 600;
    //   font-size: 16;
    //   color: #929e9e;
    // }

    .user_table_title_container {
      width: 50%;
    }

    .container_button_agreger_and_filter {
      display: flex;
      width: 49%;
      justify-content: flex-end;
      .content_header_user_button {
        display: flex;
        width: auto;
        align-items: center;
        justify-content: flex-end;
        padding-right: 6px;

        .user_button_agreger {
          align-items: center;
          display: flex;
          background: rgba(27, 114, 220, 0.921);
          color: #fff;
          padding: 11px 15px;
          border-radius: 5px;
          font-size: 14px;
          border: none;
          cursor: pointer;
          &:hover {
            color: rgb(213, 216, 223);
            cursor: pointer;
          }
        }
      }
      @media (max-width: 600px) {
        .user_filter_container {
          width: auto;
        }
      }
    }
    .user_filter_container {
      width: auto;
      margin-right: 2%;
      padding-top: 12.5px;
    }

    @media (max-width: 800px) {
      .user_table_title {
        font-size: 20px;
      }

      .container_button_agreger_and_filter {
        display: flex;
        .user_filter_container {
          width: auto;
          padding-top: 7px;
        }
      }
      .user_table_title_container {
        width: 50%;
      }
    }
  }

  @media (max-width: 500px) {
    .user_container_header {
      display: flex;
      flex-direction: column;
      .user_table_title_container {
        justify-content: center;
        height: 45px;
        width: auto;
        .user_table_title {
          display: flex;
          justify-content: center;
        }
      }
      .container_button_agreger_and_filter {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
      .user_filter_container {
        margin-bottom: 6px;
        width: 60%;
      }
    }
  }

  .user_table_container {
    @media (width: 100vw) {
      overflow: auto;
      font-size: 15px;
    }
  }

  .user_datatable {
    border-collapse: collapse;
    margin: 0 1.5%;
    height: auto;
    border-radius: 10px 10px 0 0;
    width: 97%;
    overflow: hidden;
    .user_container_title_table {
      background: rgba(31, 137, 229, 0.2);
      color: #000;
      width: 94.7%;
      height: 5em;
    }

    .user_container_table_body {
      align-items: center;
      border: 2px;
      background: rgba(113, 113, 113, 0.091);
      color: black;
      height: 4.5em;
      &:nth-child(even) {
        background-color: rgb(255, 255, 255);
      }
    }
    .user_container_name_column {
      font-weight: 400;
      text-align: center;
    }
    .user_name {
      font-weight: bold;
      text-align: center;
      @media (max-width: 500px) {
        font-size: 15px;
      }
    }
    .user_container_lastname {
      font-weight: 400;
      text-align: center;
    }
    .user_lastname {
      font-weight: bold;
      text-align: center;
      @media (max-width: 500px) {
        font-size: 15px;
      }
    }

    .user_container_username {
      font-weight: 400;
      text-align: center;
    }

    .user_username {
      font-weight: bold;
      text-align: center;
      @media (max-width: 500px) {
        font-size: 15px;
      }
    }

    .user_container_countrycode {
      font-weight: 400;
      text-align: center;
    }

    .user_countrycode {
      font-weight: bold;
      text-align: center;
      @media (max-width: 500px) {
        font-size: 15px;
      }
    }

    .user_container_cellphone {
      font-weight: 400;
      text-align: center;
    }

    .user_cellphone {
      font-weight: bold;
      text-align: center;
      @media (max-width: 500px) {
        font-size: 15px;
      }
    }
    .user_container_email {
      font-weight: 400;
      text-align: center;
    }
    .user_email {
      font-weight: bold;
      text-align: center;
      @media (max-width: 500px) {
        font-size: 15px;
      }
    }

    .user_container_acctions {
      display: flex;
      flex-direction: row;
      height: 70px;
      width: 82%;
      align-items: center;
      justify-content: center;
      .user_edit_button {
        color: rgba(16, 81, 203, 0.805);
        width: 100px;
        justify-content: space-around;
        &:hover {
          color: rgba(16, 81, 203, 1);
          cursor: pointer;
        }
      }

      .disabled_user_button {
        color: rgba(208, 10, 10, 0.705);
        &:hover {
          color: rgba(208, 10, 10, 1);
          cursor: pointer;
        }
      }

      .enabled_user_button {
        color: #22a95c;
        &:hover {
          color: #22a95c;
          cursor: pointer;
        }
      }
    }
  }
}

.users_pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80px;
  .user_pagination_button {
    background-color: $color_green_principal;
    color: white;
    margin-right: 2%;
    margin-left: 2%;
  }
}
