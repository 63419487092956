.__areas_name_min_32__ {
  font-size: 22px;
}

.__areas_name_max_32__ {
  font-size: 17px;
}

.__area_container_info_general__ {
  display: grid;
  grid-template-rows: 40% 30% 30%;

  .__area_container_name__ {
    display: grid;
    justify-self: flex-start;
    align-self: flex-end;
    font-weight: 700;
    color: $color_grey_four;
  }

  .__area_container_location__ {
    display: grid;
    justify-self: flex-start;
    align-self: center;
  }

  .__area_container_status__ {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-self: flex-start;
    align-self: center;

    .create_controller_button {
      background: rgba(27, 114, 220, 0.8);
      font-weight: 500;
      padding: 5px 10px 5px 10px;
      color: aliceblue;
      border: none;
      font-size: 1rem;
      cursor: pointer;
      display: flex;
      gap: 5px;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      font-weight: 700;
    }
    .create_controller_button:hover {
      background: rgba(27, 114, 220, 1);
    }
    .__area_container_status_number__ {
      color: $color_white_principal;
      font-weight: 500;
      padding: 5px 10px 5px 10px;
    }

    .color_percent_0 {
      border-radius: 6px;
      background-color: $color_onoff_0;
    }

    .color_percent_25 {
      border-radius: 6px;
      background-color: $color_onoff_25;
    }

    .color_percent_50 {
      border-radius: 6px;
      background-color: $color_onoff_50;
    }

    .color_percent_75 {
      border-radius: 6px;
      background-color: $color_onoff_75;
    }

    .color_percent_100 {
      border-radius: 6px;
      background-color: $color_onoff_100;
    }
  }
}

.__area_container_info_status__ {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 55% 45%;

  .__area_container_info_data__ {
    justify-self: center;
    align-self: flex-end;
    color: $color_grey_four;
    font-size: 18px;
    font-weight: 600;

    .__area_container_info_data_offline__ {
      color: $color_red_principal;
    }
  }

  .__area_container_info_names__ {
    justify-self: center;
    align-self: flex-start;
    color: $color_grey_four;
    font-size: 15px;
    font-weight: 600;
  }
}

.__area_cotnainer_info_weather_clock__ {
  display: grid;
  grid-template-rows: 60% 40%;

  .__area_cotnainer_info_clock__ {
    justify-self: center;
    align-self: flex-start;
    font-size: 17px;
    color: $color_grey_four;
  }

  .__area_cotnainer_info_weather__ {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1%;

    .__area_cotnainer_info_weather_icon_ {
      justify-self: flex-end;
      align-self: flex-end;
      font-size: 25px;
    }

    .__area_cotnainer_info_weather_temp_ {
      justify-self: flex-start;
      padding-bottom: 20px;
      align-self: flex-end;
      font-size: 25px;
    }
  }
}

@media (max-width: $screen_slg) {
  .__area_container_info_general__ {
    margin-left: 7%;
  }
}

@media (max-width: 850px) {
  .__area_container_info_general__ {
    margin-left: 0%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .__area_container_name__ {
      display: flex;
      justify-content: center;
      justify-self: unset;
      align-self: unset;
    }
  }
}
