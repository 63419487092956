.sidebar_container {
  grid-template-columns: 100%;
  height: 100vh;
  margin: 0%;

  .sidebar_logo {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(#808080, 0.3);
    

    .sidebar_img_logo {
      width: 180px;
    }
  }

  .sidebar_menu {
    display: grid;
    height: 50px;
    grid-template-columns: 100%;
    margin: 0%;

    .__menu {
      font-size: 12px;
      padding-left: 15px;
      justify-self: flex-start;
      align-self: center;
      color: $color_black_principal;
    }
  }
}

._sidebar_item {
  color: black;
  text-decoration: none;
  padding-top: 0px;
  padding-bottom: 0px;

  ._sidebar_list_item {
    &:hover {
      color: black;
      background-color: $color_green_principal;
    }
  }

  ._sidebar_list_item_inter {
    padding-left: 30px;

    ._sidebar_text {
      font-size: 10px;
    }

    &:hover {
      color: black;
      background-color: $color_green_principal;
    }
  }
}

._listitem_name_projects {
  overflow-x: hidden;
  font-size: 10px;
}

._listitem_icon {
  padding-left: 10px;
  padding-right: 10px;
}

.__icon_hab_and_control {
  padding-left: 40px;
}

.__on_item {
  margin-left: 15px;
  color: $color_white_principal !important;
  background-color: $color_green_principal !important;
}

@media (max-width: 1080px) {
  ._sidebar_item {
    color: $color_black_principal !important;

    ._dashboard_icon {
      color: $color_black_principal !important;
    }
  }

  ._listitem_name_projects {
    color: $color_black_principal !important;
  }

  ._listitem_icon {
    color: $color_black_principal !important;
  }

  .__icon_hab_and_control {
    color: $color_black_principal !important;
  }

  .__on_item {
    color: $color_white_principal !important;
    background-color: $color_green_principal !important;
  }
}
