.area_selector_container {
  display: flex;
  align-items: center;

  .selector {
    font-size: 18px;
    padding: 10px;
    border: none;
    border-bottom: 1px solid gray;

    .option {
      display: none;
      .status {
        background-color: red;
        border-radius: 45px;
        color: #fdfefe;
      }
    }
  }

  @media (max-width: $screen_xsmd) {
    .selector {
      font-size: 15px;
    }
  }
}
