.prueba {
  display: flex;
  width: 30%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.container_gestion {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 30px;
  align-items: start;
  justify-content: start;
}

.wrapped {
  display: flex;
  flex-direction: column;
  
}

.column_positions {
  width: 120px;
  display: flex;
  padding-left: 20px;
  align-items: center;
}

.container_area {
  width:120px ;
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 20px;
}

.container_area_actions {
  width:120px ;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  align-items: center;
  justify-content: space-between;
}
