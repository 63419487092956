/* Colors */
$color_green_principal: #3f9948 !default;
$color_green_secondary: #336535 !default;
$color_green_three: #4caf50e6 !default;
$color_green_four: #ddeedf !default;
$color_black_principal: #1c1c1c !default;
$color_black_secondary: #807d7d !default;
$color_black_three: #242424 !default;
$color_white_principal: #ffffff !default;
$color_white_secondary: #f9f9f9 !default;
$color_red_principal: #bf2626 !default;
$color_red_secondary: #ee0f0f !default;
$color_red_three: #ffcdd2 !default;
$color_grey_principal: #929e9e !default;
$color_grey_secondary: #e2e3e5 !default;
$color_grey_terceary: #a9aaa9 !default;
$color_grey_four: #474747 !default;
$color_grey_five: rgb(241, 243, 245) !default;
$color_blue_one: #1f89e566 !default;
$color_blue_two: #1f89e526 !default;
$color_blue_three: #1f89e5e6 !default;
$color_darkblue_one: #607d8b !default;
$color_yellow_principal: #ffaf0e !default;
$color_yellow_secundary: #dee85f !default;
$color_orange_principal: #f18509 !default;
$color_orange_secundary: #f5be80 !default;

/* Colors controller on and off*/
$color_onoff_0: #bf2626 !default;
$color_onoff_25: #ff320e !default;
$color_onoff_50: #ff660e !default;
$color_onoff_75: #ffaf0e !default;
$color_onoff_100: #3f9948 !default;

/* Screens */
$screen_xxs: 350px !default;
$screen_xsmd: 480px !default;
$screen_xs: 580px !default;
$screen_xmd: 680px !default;
$screen_md: 850px !default;
$screen_xlg: 980px !default;
$screen_lg: 1180px !default;
$screen_slg: 1380px !default;
$screen_sslg: 1600px !default;
$screen_msslg: 1850px !default;

/* Font */
$font_weather: "Open24";
$font_principal: "Montserrat", sans-serif;
