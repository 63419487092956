
.Container {
    display: grid;
    grid-template-columns: 500px, 200px, 300px;
}

.inputDates {
    
    background-color: aqua;
    width: 200px;
    height: 100px;
    border-radius: 8px;
}