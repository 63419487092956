._temperature_container_tables_ {
  display: grid;
  grid-template-columns: 100%;
  margin: 50px 20% 50px 20%;
  gap: 30px;

  .__report_temperature_table_total_title__ {
    font-size: 25px;
    display: grid;
    text-align: center;
    padding: 10px;
  }
}
