.container_header_devices {
    display: grid;
    grid-template-columns:20% 80% ;
   place-items: center;
   justify-content: center;
    width: 100%;
    background-color: #ddeedf;

    .container_logo_header{
        display: grid;
        height: 100%;
        place-items: center;
    }
    .devices_container_project{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    
}