.resetPassword_container {
  display: grid;
  grid-template-columns: 100%;

  .resetPassword_hero {
    display: grid;
    grid-template-columns: 100%;
    height: 250px;
    padding-bottom: 40px;

    .resetPassword_image {
      height: 100px;
      width: 100px;
      justify-self: center;
      align-self: flex-end;
    }
  }

  .resetPassword_input_container {
    display: grid;
    padding-bottom: 25px;

    .resetPassword_input_and_title_container {
      justify-self: center;
      align-self: center;

      .resetPassword_input_title_container {
        font-size: 15px;
        font-weight: 600;
        padding-bottom: 10px;
      }

      .resetPassword_input {
        border: 1px solid rgba(0, 0, 0, 0.5);
        padding: 15px 20px;
        font-size: 18px;
        width: 250px;
        border-radius: 5px;
      }

      .resetPassword_span_required_input {
        font-size: 12px;
        color: $color_red_principal;
        margin-top: 10px;
      }
    }
  }

  .resetPassword_submit_container {
    display: grid;
    padding-bottom: 50px;
    margin-top: 20px;

    .resetPassword_submit {
      background: rgba(63, 153, 72, 0.8);
      border: 1px solid rgba(255, 255, 255, 0.5);
      justify-self: center;
      align-self: center;
      color: $color_white_principal;
      padding: 15px 20px;
      width: 290px;
      font-size: 18px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
