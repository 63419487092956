.gestor-accordion {
  display: flex;
  flex-direction: column;
  background-color: cadetblue;
}

.option-accordion {
  display: flex;
  flex-direction: row;
  background-color: white;
}

.__color_button_save {
  color: #fff;

  &:hover {
    color: #e5e0e0;
  }
}

.__color_button_disabled {
  color: #c5c4c4db;
}
