.__generic_sensor_wifi_new {
  padding-top: 5px;
}

._generic__status__wifi_new {
  @extend %_generic__status;
  transform: rotate(45deg);

  .__icon_wifi_sensor_new {
    color: $color_green_principal;
    font-size: 20px;
  }

  .__icon_wifi_sensor_offline_new {
    color: $color_grey_principal;
    font-size: 20px;
  }
}

._battery__status_new {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  cursor: pointer;
}
.__percent_new {
  justify-self: flex-end;
  align-self: center;
  font-size: 12px;
}

.__battery_new {
  display: flex;
  justify-self: flex-start;
  flex-direction: row;
  align-self: center;
  cursor: pointer;

  .__icon_battery_sensor_new {
    color: $color_green_principal;

    transform: rotate(90deg);
  }
  .__icon_battery_sensor_unknown_new {
    color: $color_yellow_principal;
    transform: rotate(90deg);
  }
  .__icon_battery_sensor_alert_new {
    color: $color_red_secondary;
    transform: rotate(90deg);
  }
}
