.principal_container {
  display: grid;
  grid-template-columns: 15% 85%;
  grid-template-columns: 20% 80%;

  .principal_container_sidebar {
    display: grid;
    background-color: $color_white_secondary;
    position: sticky;
    top: 0;
    height: 100vh;
  }

  .principal_container_content {
    .navbar_container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      height: 80px;
      background-color: $color_green_principal;
      box-shadow: 0 6px 3px -3px rgba($color_grey_principal, 0.6);
      position: sticky;
      top: 0;
      z-index: 10;

      .navbar_title {
        justify-self: start;
        padding-left: 30px;
        align-self: center;
        font-size: 18px;
        font-weight: 500;
        color: $color_white_principal;
        .nameText {
          color: black;
        }

        .navbar_menu {
          display: none;
          font-size: 25px;
          padding-right: 30px;
          cursor: pointer;
        }

        .navbar_close_container {
          z-index: 1;
          display: inline-block;
          position: fixed;
          height: 100%;
          width: 300px;
          top: 0;
          left: 0;
          // overflow-y: scroll;
          background-color: $color_white_principal;

          .navbar_close {
            display: grid;
            color: $color_black_principal;
            padding: 10px 15px 10px 0;
            text-align: end;
            font-size: 25px;
            cursor: pointer;
          }
        }
      }

      .navbar_btn_logout {
        display: flex;
        justify-self: flex-end;
        align-self: center;
        margin-right: 20px;
        color: $color_white_principal;
        font-size: 25px;
        padding: 5px 12px;
        border-radius: 50%;
        cursor: pointer;
      }
    }

    .principal_container_views {
      display: grid;
    }

    .active_menu {
      background: rgba($color_grey_terceary, 0.6);
      opacity: 0.7;
    }
  }

  .button_back {
    padding: 5px 10px 5px 10px;
    border-radius: 50%;
    font-size: 25px;
    color: $color_white_principal;
    cursor: pointer;
    border: none;
    background-color: $color_green_principal;

    &:hover {
      background-color: $color_green_three;
    }
  }
}

@media (max-width: $screen_sslg) {
  .principal_container {
    grid-template-columns: 18% 82%;
  }
}

@media (max-width: $screen_slg) {
  .principal_container {
    grid-template-columns: 22% 78%;
  }
}

@media (max-width: 1080px) {
  .principal_container {
    grid-template-columns: 100%;

    .principal_container_sidebar {
      display: none;
    }

    .principal_container_content {
      .navbar_container {
        .navbar_title {
          .navbar_menu {
            display: inline;
          }
        }
      }
    }
  }
}
