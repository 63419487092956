._login_container_principal {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  height: 100vh;
  justify-self: center;
  align-items: center;

  .principal {
    display: grid;
    margin: 50px 20% 50px 20%;
    grid-template-columns: repeat(2, 50%);
    border-radius: 20px;
    box-shadow: 3px 3px 3px 1px rgba(0, 0, 0, 0.2);

    ._hero {
      grid-template-columns: 100%;
      background-color: $color_green_principal;
      border-radius: 20px 0 0 20px;
    }
  }
  .lenguaje {
    display: flex;
    align-self: flex-start;
    justify-self: flex-end;
  }
}

._form {
  display: grid;
  grid-template-rows: 30% 11% 34% 8% 5% 12%;

  ._container__logo {
    display: grid;
    height: 150px;

    ._logo {
      justify-self: center;
      align-self: flex-end;
    }
  }

  ._container__title {
    display: grid;
    height: 50px;

    ._title {
      font-size: 25px;
      font-weight: 600;
      color: $color_grey_principal;
      justify-self: center;
      align-self: center;
    }

    @media (max-width: 1440px) {
      ._title {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 1440px) {
    ._container__title {
      height: 35px;
    }
  }

  %btn {
    margin-left: 15%;
    margin-right: 15%;
    border-radius: 15px;
    border: none;
    height: 35px;
    font-size: 14px;
    padding-left: 15px;
  }

  ._container_inputs {
    display: grid;
    height: 140px;

    ._btn_input {
      @extend %btn;
      background-color: $color_grey_secondary;
      color: $color_black_principal;
    }

    @media (max-width: 1440px) {
      ._btn_input {
        height: 30px;
      }
    }

    @media (max-width: 1200px) {
      ._btn_input {
        height: 28px;
      }
    }

    ._container_password {
      display: flex;
      margin-left: 15%;
      margin-right: 15%;
      justify-content: space-between;

      ._input_password {
        width: 100%;
        border-radius: 15px;
        border: none;
        height: 35px;
        font-size: 14px;
        padding-left: 15px;
        background-color: $color_grey_secondary;
        color: $color_black_principal;
      }
      ._container_icon {
        position: sticky;
        margin-top: -5px;
      }

      @media (max-width: 1440px) {
        ._input_password {
          height: 30px;
        }
      }
    }

    @media (max-width: 1200px) {
      ._input_password {
        height: 28px;
      }
    }

    ._btn_submit {
      @extend %btn;
      background-color: $color_green_principal;
      color: white;
      cursor: pointer;
    }

    @media (max-width: 1440px) {
      ._btn_submit {
        height: 30px;
      }
    }

    @media (max-width: 1200px) {
      ._btn_submit {
        height: 28px;
      }
    }
  }

  ._container_forget_pass {
    display: grid;
    height: 50px;

    ._forget_pass {
      justify-self: center;
      align-self: center;
      font-weight: 600;
      cursor: pointer;
    }
  }

  ._container_info_contact {
    display: grid;
    justify-self: center;
    align-self: center;

    ._info_contact {
      justify-self: center;
      align-self: center;
      font-weight: 600;
      cursor: pointer;
      text-decoration: none;
      color: black;
    }

    @media (max-width: 1300px) {
      ._info_contact {
        font-size: 14px;
      }
    }
  }

  .forgot_password {
    display: grid;
    justify-self: center;
    align-self: center;

    a {
      text-decoration: none;
      color: $color_black_principal;
      font-weight: 600;
      font-size: 14px;
    }
  }

  .language_select {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 1300px) {
    ._container__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 130px;
    }
    ._logo {
      height: 110px;
      width: 110px;
    }
  }

  @media (max-width: 1300px) {
    ._container_inputs {
      display: grid;
      grid-template-rows: 15% auto 15%;
    }
  }
}

@media (max-width: 1300px) {
  ._form {
    display: grid;
    grid-template-rows: 35% 10% auto auto 10%;
  }
}

// @media(max-width: 1200px){
//   ._form {
//     display: grid;
//     grid-template-rows: 35% 10% 32% 15% 2%;
//   }
// }

@media (max-width: 930px) {
  ._login_container_principal {
    height: 100vh;

    .principal {
      margin: 0px 5% 0 5%;
      grid-template-columns: repeat(2, 50%);
      margin-bottom: 15px;

      ._hero {
        border-radius: 20px 20px 0 0;
      }
    }
  }
}

@media (max-width: 600px) {
  ._login_container_principal {
    height: 100vh;

    .principal {
      margin: 0px 5% 0 5%;
      grid-template-columns: 100%;
      margin-bottom: 15px;

      ._hero {
        border-radius: 20px 20px 0 0;
      }
    }
  }
}

@media (max-width: $screen_xmd) {
  ._login_container_principal {
    height: 100vh;

    .principal {
      border: none;
      box-shadow: none;
      grid-template-columns: 100%;

      ._hero {
        display: none;
      }
    }
  }
}
