.header_model_battery_report {
  background-color: #3f9948;
  height: 40px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  color: white;
  font-size: 20px;

  .header_model_battery_report_title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;

    @media (max-width: 480px) {
      font-size: 16px;
    }
  }
}

.battery_devices {
  align-self: center;
  padding: 30px;
  width: 75%;

  .battery_device_title_container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;

    .battery_device_title_level {
      justify-self: center;
    }
  }

  .battery_devices_title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
  }

  .battery_device_empty {
    display: grid;
    justify-self: center;
    text-align: center;
    padding: 15px;
    font-size: 20px;
  }

  .battery_device_container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid rgba(146, 158, 158, 0.3);

    .battery_device_date {
      align-self: center;
    }

    .battery_device_level {
      display: flex;

      .battery_device_averange {
        justify-self: center;
        align-self: center;
      }

      .battery_device_level_icon {
        justify-self: center;
        align-self: center;
        transform: rotate(90deg);
      }

      .__icon_battery_sensor {
        color: $color_green_principal;
      }
      .__icon_battery_sensor_unknown {
        color: $color_yellow_principal;
      }

      .__icon_battery_half_level {
        color: $color_orange_principal;
      }

      .__icon_battery_sensor_alert {
        color: $color_red_secondary;
      }

      // .color_battery_100 {
      //   color: $color_onoff_100;
      // }

      // .color_battery_30-10 {
      //   color: $color_onoff_75;
      // }

      // .color_battery_10-0 {
      //   color: $color_onoff_0;
      // }
    }
  }
}
