%_generic_container_clasification_extend {
  ._generic_clasification {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: center;
    align-self: center;
  }

  .__loading_clasification {
    opacity: 0.2;
  }

  .__loading_devices {
    display: grid;
    justify-self: center;
    align-self: center;
    margin-left: 35px;
    height: 100px;
    width: 100px;
    z-index: -1;
  }

  ._generic_toggle {
    justify-self: center;
    align-self: center;
    ._generic__toggle {
      transform: scale(2);
    }

    ._sensor_humedity_text {
      font-size: 16px;
    }
  }

  .__loading_toogle {
    opacity: 0.2;
  }
}

.watts_kwh {
  grid-template-rows: 25% 55% 20%;
}

.off_watts_kwh {
  grid-template-rows: 30% 70%;
}

._generic_container {
  display: grid;
  box-shadow: 1px 2px 1px 1px rgba(grey, 0.3);
  border: rgba($color: $color_grey_principal, $alpha: 0.3) 1px solid;
  height: 145px;
  border-radius: 15px;

  ._generics_container_title_status {
    display: grid;
    grid-template-columns: 65% 35%;
    background-color: rgba($color: gray, $alpha: 0.1);
    border-radius: 15px 15px 0px 0px;

    ._generic__title {
      justify-self: flex-start;
      align-self: center;
      font-size: 17px;
      padding-left: 5%;
    }

    ._generic__kwh {
      justify-self: flex-start;
      align-self: center;
      font-size: 6px;
      padding-left: 5%;
    }

    %_generic__status {
      justify-self: center;
      align-self: center;
      margin-left: 30px;
    }

    ._generic__status__alert {
      @extend %_generic__status;

      .__icon_alert_sensor {
        color: $color_red_principal;
        font-size: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .__icon_alert_sensor img {
        padding-top: 0px;
      }
    }
    ._generic__status__alert_off {
      @extend %_generic__status;

      .__icon_alert_sensor {
        color: $color_red_principal;
        font-size: 15px;
      }
    }

    .battery_status {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0px 15px;
      gap: 5px;

      .battery_percentage {
        font-size: 12px;
      }

      .battery_icon_container {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(90deg);
        cursor: pointer;

        .__icon_battery_sensor {
          display: flex;
          color: $color_green_principal;
        }
        .__icon_battery_sensor_unknown {
          display: flex;
          color: $color_yellow_principal;
        }

        .__icon_battery_half_level{
          display: flex;
          color: $color_orange_principal
        }

        .__icon_battery_sensor_alert {
          display: flex;
          color: $color_red_secondary;
        }
      }
    }

    .__generic_sensor_wifi {
      padding-top: 5px;
    }

    ._generic__status__wifi {
      @extend %_generic__status;
      transform: rotate(45deg);

      .__icon_wifi_sensor {
        color: $color_green_principal;
        font-size: 20px;
      }

      .__icon_wifi_sensor_offline {
        color: $color_grey_principal;
        font-size: 20px;
      }
    }

    ._battery__status {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      cursor: pointer;

      .__percent {
        justify-self: flex-end;
        align-self: center;
        font-size: 12px;
      }

      .__battery {
        justify-self: flex-start;
        align-self: center;
        transform: rotate(90deg);

        .__icon_battery_sensor {
          transform: rotate(90deg);
          color: $color_green_principal;
          margin-left: 3px;
        }
        .__icon_battery_sensor_unknown {
          transform: rotate(90deg);
          color: $color_yellow_principal;
          margin-left: 3px;
        }
        .__icon_battery_sensor_alert {
          transform: rotate(90deg);
          color: $color_red_secondary;
          margin-left: 3px;
        }
      }
    }
  }

  .__status_comfailure__ {
    background-color: rgba($color_red_principal, 0.3);
  }

  ._generic_container_clasification {
    display: grid;
    grid-template-columns: 49% 2% 49%;

    @extend %_generic_container_clasification_extend;
  }
  ._generic_container_clasification_action {
    display: grid;
    grid-template-columns: 49% 2% 35% 14%;

    @extend %_generic_container_clasification_extend;
  }
}

.__title_device_fails__ {
  text-align: center;
  font-size: 18px;
  margin: 15px 0 15px 0;
}

@media (max-width: $screen_xsmd) {
  .__title_device_fails__ {
    font-size: 15px;
  }
}

.__container_doorLock__ {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 80% 20%;

  .__loading_text_container_doorlock__ {
    justify-self: center;
    align-self: center;
  }
}

.descrptcion_doorlock_loading {
  font-size: 22px;
}

.__message_doorLock__ {
  font-size: 18px;
}

.__device_fails__ {
  display: grid;
  grid-template-columns: 30% 70%;
  height: 70px;
  margin: 5px 15% 25px 15%;
  row-gap: 5px;
  border: 1px solid rgba(#000000, 0.1);
  padding: 10px 15px;

  .__img_device_fails__ {
    justify-self: center;
    align-self: center;
  }

  .__device_fails_names_container__ {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(2, 1fr);

    .__device_fails_name__ {
      font-weight: bold;
      font-size: 18px;
      justify-self: center;
      align-self: center;
    }

    .__description_device_fails {
      font-size: 14px;
      justify-self: center;
      align-self: center;
      text-align: center;
    }
  }
}
