._datecustom_container_input_ {
  display: grid;
  grid-template-columns: 15% 20% 30% 20% 15%;
  height: 200px;
  margin-top: 40px;
  
  .__backbutton_datecustom__ {
      margin: 55px 0px;
      display: grid;
      grid-template-columns: 100%;
      border-bottom: 1px solid rgba($color_grey_principal, 0.3);
      border-top: 1px solid rgba($color_grey_principal, 0.3);
      
      .__button_back__{
          justify-self: flex-end;
          align-self: center;

          ._input_icon_button_back_ {
              cursor: pointer;
              font-size: 15px;
              justify-self: flex-end;
              align-self: center;
              background-color: rgba($color_green_principal, 1);
              padding: 5.5px 10px 8px 10px;
              color: $color_white_principal;
              border-radius: 5px 0 0 5px;
              border: 1px solid rgba($color_grey_principal, 0.8);
          }

          ._button__date_ {
              cursor: pointer;
              font-size: 15px;
              border-radius: 0px 5px 5px 0px;
              justify-self: flex-end;
              align-self: center;
              background-color: rgba($color_green_principal, 0.8);
              padding: 8px 10px 8px 10px;
              color: $color_white_principal;
              border: 1px solid rgba($color_green_principal, 0.8);
          }
      }

      .__button_next__{
          justify-self: flex-start;
          align-self: center;

          ._input_icon_button_next_ {
              cursor: pointer;
              font-size: 15px;
              justify-self: flex-start;
              align-self: center;
              background-color: rgba($color_green_principal, 1);
              padding: 5.5px 10px 8px 10px;
              border-radius: 0px 5px 5px 0px;
              color: $color_white_principal;
              border: 1px solid rgba($color_grey_principal, 0.8);
          }
          
          ._button__date_ {
              cursor: pointer;
              border-radius: 5px 0 0 5px;
              font-size: 15px;
              justify-self: flex-start;
              align-self: center;
              background-color: rgba($color_green_principal, 0.8);
              padding: 8px 10px 8px 10px;
              color: $color_white_principal;
              border: 1px solid rgba($color_green_principal, 0.8);
          }
      }
  }

  .dropdown {
      padding: 55px 0px;
      display: grid;
      grid-template-columns: 100%;

      
      .__inputs_container_calendar__ {
          display: grid;
          grid-template-columns: 20% 80%;
          border-bottom: 1px solid rgba($color_grey_principal, 0.3);
          border-top: 1px solid rgba($color_grey_principal, 0.3);
  
          ._input_icon_calendar_ {
              cursor: pointer;
              font-size: 20px;
              justify-self: flex-end;
              align-self: center;
              background-color: rgba($color_grey_terceary, 0.1);
              padding: 2px 10px 6px 10px;
              border: 1px solid rgba($color_grey_principal, 0.3);
          }
          
          ._inputs__date_ {
              font-size: 15px;
              justify-self: flex-start;
              align-self: center;
              width: 300px;
              border: 1px solid rgba($color_grey_principal, 0.3);
              // border-bottom: 1px solid rgba($color_grey_principal, 0.5);
              background-color: rgba($color_grey_terceary, 0.1);
              padding: 8px 10px;
              // width: 300px;
              // font-size: 12px;
              // margin: 15px 10px;
              text-align: center;
              font-family: Verdana, Geneva, Tahoma, sans-serif;
              cursor: pointer;
          }
      }


      .dropdown-content-true {
          display: block;
      }

      .dropdown-content-false {
          display: none;
      }

      .dropdown-content {
          position: absolute;
          background-color: white;
          margin-top: -20px;
          margin-left: -133px;
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
          padding: 12px 16px;
          z-index: 1;

          ._datecustom_titles_container {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              column-gap: 10px;
              text-align: center;
              padding: 5px;
              font-size: 15px;
          }

          ._datecustom_container_buttons__ {
              text-align: end;
              margin-right: 10px;
          
              ._datecustom_container_button_ {
                  padding: 10px 0 10px 0;
                  margin-right: 10px;
                  grid-template-columns: 100%;
                  height: 30px;

                  ._datecustom_button_ {
                      justify-self: flex-end;
                      width: 150px;
                      border-radius: 5px;
                      border: none;
                      cursor: pointer;
                      padding: 5px;
                      font-size: 15px;
                      background-color: $color_green_three;
                      color: $color_white_principal;

                      &:hover {
                          background-color: rgba($color_green_three, 0.7);
                      }
                  }
              }

              ._datecustom_container_button_cancel_ {
                  padding: 0 0 10px 0;
                  grid-template-columns: 100%;
                  height: 30px;

                  ._datecustom_button_cancel_ {
                      border: none;
                      justify-self: flex-end;                        
                      width: 150px;
                      border-radius: 5px;
                      padding: 5px;
                      cursor: pointer;
                      font-size: 15px;
                      background-color: rgba($color_red_secondary, 0.7);
                      color: $color_white_principal;

                      &:hover {
                          background-color: rgba($color_red_secondary, 0.5);
                      }
                  }
              }
          }
      }
  }
}

@media (max-width: $screen_lg) {
  ._datecustom_container_input_ {
      grid-template-columns: 20% 60% 20%;
  }
}

@media (max-width: $screen_xlg) {
  ._datecustom_container_input_ {
      grid-template-columns: 15% 70% 15%;
  }
}

@media (max-width: $screen_md) {
  ._datecustom_container_input_ {
      grid-template-columns: 10% 80% 10%;
  }
}