.confirmation_container {
  margin: 200px 30%;

  .confirmation_icon_text {
    display: grid;
    padding: 30px 0;

    span {
      justify-self: center;
      align-self: center;
    }
  }

  .size_text {
    font-size: 20px;
  }

  .confirmation_back_login_container {
    display: grid;

    .confirmation_back_login {
      justify-self: center;
      align-self: center;
      cursor: pointer;

      a {
        text-decoration: none;
        color: $color_black_principal;
      }
    }
  }
}
