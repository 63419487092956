._area_container_title_true {
  grid-template-columns: 80% 20%;
}

._area_container_title_false {
  grid-template-columns: 100%;
}

._area_container {
  display: grid;
  height: 80px;
  grid-template-columns: 100%;
  grid-template-rows: 35% 65%;
  border: 1px solid rgba(grey, 0.3);
  box-shadow: 2px 1px 2px 1px rgba($color_grey_principal, 0.4);
  border-radius: 10px;

  &:hover {
    box-shadow: rgba($color_grey_principal, 0.6) 0px 7px 29px 0px;
  }

  ._area_container_title {
    display: flex;
    justify-content: flex-start;
    border-radius: 5px 5px 0 0;

    .color_title_pluggin {
      color: $color_black_principal;
    }

    .__title_area {
      cursor: pointer;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      height: 100%;
      font-size: 15px;
      width: 100%;
      padding-top: 3px;

      font-weight: bold;
    }

    .failed_device_button {
      width: 15%;
      .__icon_warning__ {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 17px;
        width: 17px;
        background-color: rgb(254, 219, 65);
        box-shadow: 2px 1px 2px 1px rgb(214, 180, 29);
        border-radius: 12px;
        margin-top: 3px;
        cursor: pointer;
      }
    }
  }

  .__status_plugin_motel {
    margin-top: 5px;
    font-size: 12px;
    display: flex;
    justify-self: flex-start;
    padding-left: 15px;
    cursor: pointer;

    ._span__status_plugin_motel {
      align-self: center;
      height: 10px;
      width: 10px;
      border-radius: 50%;
    }

    ._text__status_plugin_motel {
      padding-left: 2px;
      align-self: center;
    }
  }

  .__status_libre_area {
    background-color: $color_green_principal;
  }

  .__status_ocupado_area {
    background-color: $color_blue_three;
  }

  .__status_null_area {
    background-color: $color_grey_principal;
  }

  .libre_motel_plugin {
    background-color: #17e708f0;
    color: black;
  }

  .sin_estado_motel_plugin {
    background-color: #fff;
    color: black;
    border: 1px solid gray;
  }

  .sucia_motel_plugin {
    background-color: #ff00ff;
    color: white;
  }

  .vendida_motel_plugin {
    background-color: #0000ff;
    color: white;
  }

  .reservada_motel_plugin {
    background-color: #00ffff;
    color: black;
  }

  .bloqueada_motel_plugin {
    background-color: #ff0000;
    color: white;
  }

  .aseo_motel_plugin {
    background-color: #ff00ff;
    color: white;
  }

  .__device_offline__ {
    display: grid;
    font-size: 9px;
    font-weight: bold;
    color: $color_white_principal;
    background-color: rgba($color_red_principal, 0.9);

    .__device_title_offline__ {
      justify-self: center;
      align-self: center;
    }
  }

  ._area_container_reports {
    display: grid;
    cursor: pointer;

    %icon__ {
      margin: 0px 8px 0 8px;
      font-size: 15px;
      border-radius: 50%;
      justify-self: center;
      align-self: center;
    }

    .active_fails_devices {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    .desactive_fails_devices {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    ._area_container_report_icon {
      padding-right: 5px;
      align-self: center;
      justify-self: center;

      ._area_container_img_ocupation {
        @extend %icon__;
        padding: 1px 6px;
        border: 2px solid $color_black_three;
        color: $color_black_three;
      }

      ._area_container_img_energy {
        @extend %icon__;
        border: 2px solid rgba(#3b5cf1, 1);
        padding: 1px 5px;
        color: rgba(#3b5cf1, 1);
      }

      ._area_container_disabled_img_energy {
        @extend %icon__;
        border: 2px solid $color_grey_principal;
        padding: 1px 5px;
        color: $color_grey_principal;
      }

      ._area_container_fails_socket {
        margin-left: 15px;
      }
    }

    .__areas_container_offline__ {
      padding-bottom: 8px;
      justify-self: center;
      align-self: center;
    }

    .__areas_container_offline__ span {
      font-size: 14px;
      font-weight: bold;
      color: $color_red_principal;
    }

    .__device_fails__ {
      display: grid;

      .__device_fails_icon__ {
        justify-self: flex-start;
        align-self: flex-start;
        margin-top: 5px;
        font-size: 15px;
        color: $color_black_principal;
      }
    }
  }

  .__area_controller_off__ {
    height: 52px;
    background-color: $color_red_three;
    border-radius: 0 0 10px 10px;
  }
}
