.DateRangeAndCalendarContainer {
  display: flex;
  justify-content: center;
  .ReactDateRangeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 25px 0px 15px 0px;
    width: 100%;

    .ButtonsAndCalendar {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-evenly;
      gap: 10px;
      width: auto;

      .DescriptionAndCalendar {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 3px;

        .Description {
          font-family: Montserrat, "sans serif";
          font-size: "13px";
          color: gray;
          margin-top: 3px;
        }
      }
    }
  }

  .DateRangeButtonContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    color: white;
    border: none;
    border-radius: 5px;
    background-color: green;
    font-size: 15px;
    padding: 10px 10px;
    cursor: pointer;
    width: max-content;
  }

  .DateRangeInputContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    font-size: 18px;
    padding: 6px 10px;
    width: fit-content;
    cursor: pointer;
  }

  .Calendar {
    display: flex;
    justify-content: center;
    position: absolute;
    margin-top: 85px;
    border-radius: 10px;
    z-index: 10;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    .DropDownButtonsAndCalendars {
      display: flex;
      flex-direction: column;
      .DropDownButtonsContainer {
        display: flex;
        justify-content: flex-end;
        gap: 5px;
        padding: 0px 10px 8px 0px;
        background-color: #ffffff;
        .DropDownCancelButton {
          color: #fdfefe;
          padding: 5px 15px;
          background-color: #943126;
          border-radius: 5px;
          cursor: pointer;
          &:hover {
            background-color: #c18680;
          }
        }
        .DropDownAcceptButton {
          color: #fdfefe;
          padding: 5px 15px;
          background-color: #1d8348;
          border-radius: 5px;
          cursor: pointer;
          &:hover {
            background-color: #7cb092;
          }
        }
      }
    }
  }

  @media (max-width: $screen_xsmd) {
    .ReactDateRangeContainer {
      .ButtonsAndCalendar {
        .DateRangeButtonContainer {
          display: flex;
          align-items: center;
          grid-gap: 5px;
          gap: 5px;
          color: white;
          border: none;
          border-radius: 5px;
          background-color: green;
          font-size: 12px;
          padding: 10px 10px;
          cursor: pointer;
          width: max-content;
        }
      }
    }
    .DateRangeInputContainer {
      display: flex;
      align-items: center;
      gap: 5px;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      font-size: 15px;
      padding: 6px 10px;
      width: max-content;
      cursor: pointer;
    }
  }
}
