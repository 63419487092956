// .__details_measurer_consumption_voltage_variable__ {
//   margin-top: 15px;
//   margin-bottom: 10px;
//   width: 100%;
//   border-radius: 15px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   background-color: rgba(255, 255, 255, 0.5);
//   .__details_measurer_information_card__ {
//     width: 325px;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     .__details_measurer_information_card_title__ {
//       width: 100%;
//       height: 20%;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       font-size: 25px;
//     }
//     .__details_measurer_information_card_container__ {
//       display: flex;
//       flex-direction: row;
//       width: 100%;
//       height: 80%;
//       span {
//         color: #54a560;
//       }
//       .__details_measurer_information_card_now__ {
//         width: 100%;
//         display: flex;
//         flex-direction: column;
//         justify-content: space-around;
//         align-items: center;

//         span {
//           font-family: $font_weather;
//           font-size: 65px;
//         }
//       }
//     }
//   }

//   .__details_measurer_consumption_voltage_variable_title__ {
//     height: 15%;
//     font-size: 20px;
//     margin-top: 3px;
//     padding-left: 15px;
//   }
//   .__details_measurer_consumption_voltage_variable_value__ {
//     height: 75%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
//   table {
//     width: 170px;
//   }
//   td {
//     text-align: center;
//     padding: 5px;
//     //border: #85c768 2px solid;
//     border-style: solid;
//     border: #000000 2px solid;
//   }
// }

._carTotalNew_ {
  display: grid;
  width: 100%;
  height: 220px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  font-family: "Quicksand", sans-serif;
  border-radius: 10px;
  .__details_measurer_information_card_title__ {
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: grid;
    color: white;
    font-size: 16px;
    justify-items: center;
    width: 100%;
    height: 44px;
  }
  .__details_measurer_consumption_voltage_variable_value__ {
    display: flex;
    align-items: initial;
    color: #47a23f;
    justify-content: center;
    font-size: -webkit-xxx-large;
    font-family: "Quicksand", sans-serif;

    .unit_measurer {
      margin-top: -20px;
      margin-left: 8px;
      font-size: 1.8rem;
      display: flex;
      align-items: center;
      font-weight: 700;
      color: #535353;
    }
  }

  .background_CFA {
    background-color: #47a23f;
    background-image: linear-gradient(90deg, #808080, #b3b3b3);
  }

  .container_values_songs {
    display: flex;
    position: inherit;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 5px;
    justify-content: space-evenly;

    .container_value_button {
      display: flex;
      color: rgb(255, 255, 255);
      padding: 1px;
      cursor: pointer;
      font-weight: bold;
      background-color: #808080;
      align-items: center;
      justify-content: center;
      height: 40%;
      width: 20%;
      border-radius: 10px;
    }
  }

  .color_values {
    padding: 1px;
    color: #808080;
    font-weight: bold;
    cursor: pointer;
  }
}
