._weather_container {
  @extend %__styles_weather_container;
  border: rgba($color: $color_grey_principal, $alpha: 0.3) 1px solid;
  @extend %__styles_complement;
}

._weather_container_pluggin {
  @extend %__styles_weather_container;
  border: rgba($color: $color_grey_principal, $alpha: 0.3) 1px solid;
  @extend %__styles_complement;
}

%__styles_complement {
  ._weather_container_title_status {
    display: grid;
    grid-template-columns: 60% 20% 20%;
    background-color: rgba($color: gray, $alpha: 0.1);
    border-radius: 15px 15px 0px 0px;

    ._weather__title {
      justify-self: flex-start;
      align-self: center;
      font-size: 20px;
      padding-left: 5%;
    }

    .battery_status {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0px 15px;
      gap: 5px;

      .battery_percentage {
        font-size: 12px;
      }

      .battery_icon_container {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(90deg);

        .__icon_battery_sensor {
          display: flex;
          color: $color_green_principal;
        }
        .__icon_battery_sensor_unknown {
          display: flex;
          color: $color_yellow_principal;
        }

        .__icon_battery_half_level {
          display: flex;
          color: $color_orange_principal;
        }

        .__icon_battery_sensor_alert {
          display: flex;
          color: $color_red_secondary;
        }
      }
    }

    .__battery {
      display: flex;
      flex-direction: row;
      align-self: center;

      .__percent {
        display: flex;
        flex-direction: column;

        align-self: center;
        font-size: 12px;
      }

      .__icon_battery_sensor {
        transform: rotate(90deg);
        color: $color_green_principal;
        margin-left: 3px;
      }
      .__icon_battery_sensor_unknown {
        transform: rotate(90deg);
        color: $color_yellow_principal;
        margin-left: 3px;
      }
      .__icon_battery_sensor_alert {
        transform: rotate(90deg);
        color: $color_red_secondary;
        margin-left: 3px;
      }
    }

    .__icon_offline__ {
      transform: rotate(0deg) !important;
    }

    ._weather__status {
      justify-self: center;
      align-self: center;
      transform: rotate(45deg);

      .__icon_wifi {
        color: $color_green_principal;
        font-size: 20px;
      }
    }
  }

  ._weather_offline {
    background-color: rgba($color_red_principal, 0.3);
  }

  .__loading_weather_other {
    opacity: 0.2;
  }

  ._weather_container_temperature {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .__weather_temperatur_off {
      background-color: rgba($color_grey_terceary, 0.5) !important;
      border: 1px solid $color_grey_terceary !important;
    }

    ._weather_temperature {
      display: flex;
      justify-content: center;
      width: 45%;
      height: 75%;
      border: 1px solid $color_blue_one;
      background-color: rgba($color_blue_two, 0.3);
      border-radius: 10px;
      // padding: 0 5px 5px 2.5px;

      .__temperature {
        display: flex;
        justify-content: center;
        color: $color_blue_three;
      }

      .__temperatur_off {
        color: $color_grey_four !important;
      }
    }

    ._weather_container_up_down {
      display: grid;
      grid-template-rows: repeat(2, 1fr);

      ._weather_btn_up_down {
        display: grid;
        grid-template-columns: 65% 35%;

        ._weather_temperature {
          font-size: 50px;
          justify-self: center;
          align-self: center;
          border: 1px solid $color_blue_one;
          background-color: rgba($color_blue_two, 0.3);
          border-radius: 10px;
          padding: 0 10px 10px 5px;

          .__temperature {
            font-family: $font_weather;
            font-size: 70px;
            margin-left: 20px;
            color: $color_blue_three;
          }
        }

        %__btn_up_down {
          font-size: 25px;
          font-family: Tahoma;
          padding: 5px 25px 5px 25px;
          border: none;
          border-radius: 10px;
          background-color: $color_grey_secondary;
          cursor: pointer;
          color: $color_grey_principal;

          &:hover {
            background-color: $color_grey_principal;
            color: $color_white_principal;
          }
        }

        .__btn_up {
          @extend %__btn_up_down;
          justify-self: flex-start;
          align-self: flex-end;
          margin-bottom: 5px;
        }

        .__btn_down {
          @extend %__btn_up_down;
          justify-self: flex-start;
          align-self: flex-start;
          margin-top: 5px;
        }
      }
    }
  }

  .__loading_weather {
    display: grid;
    justify-self: center;
    align-self: center;
    margin-left: 45px;
    z-index: -1;
    width: 100px;
    height: 100px;
  }

  ._indoor_weather {
    display: grid;

    .__indoor {
      justify-self: center;
      align-self: center;
      font-size: 14px;
      font-weight: bold;
      color: $color_grey_terceary;
    }
  }

  ._weather_container_mode {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);

    %__weather_mode {
      padding: 5px 15px;
      border-radius: 5px;
      background-color: $color_grey_secondary;
      cursor: pointer;

      &:hover {
        background-color: $color_grey_principal;
      }
    }

    ._weather_mode_cool {
      @extend %__weather_mode;
      padding: 10px 25px;
      margin: 0 5px 5px 0;
      justify-self: flex-end;
      align-self: flex-end;
    }

    ._weather_mode_cool_bg {
      @extend %__weather_mode;
      padding: 10px 25px;
      margin: 0 5px 5px 0;
      background-color: $color_blue_three;
      border: 1px solid $color_blue_three;
      color: $color_white_principal;
      justify-self: flex-end;
      align-self: flex-end;
    }

    ._weather_mode_heat {
      @extend %__weather_mode;
      margin: 0 0px 5px 5px;
      padding: 10px 25px;
      justify-self: flex-start;
      align-self: flex-end;
    }

    ._weather_mode_heat_bg {
      @extend %__weather_mode;
      margin: 0 0px 5px 5px;
      padding: 10px 25px;
      color: $color_white_principal;
      background-color: $color_red_principal;
      justify-self: flex-start;
      align-self: flex-end;
    }

    ._weather_mode_auto {
      @extend %__weather_mode;
      margin: 5px 5px 0 0px;
      padding: 10px 25px;
      justify-self: flex-end;
      align-self: flex-start;
    }

    ._weather_mode_auto_bg {
      @extend %__weather_mode;
      margin: 5px 5px 0 0px;
      padding: 10px 25px;
      justify-self: flex-end;
      align-self: flex-start;
      color: $color_white_principal;
      background-color: $color_green_principal;
    }

    ._weather_mode_off {
      @extend %__weather_mode;
      padding: 10px 30px;
      margin: 5px 0px 0px 5px;
      justify-self: flex-start;
      align-self: flex-start;
    }

    ._weather_mode_off_bg {
      @extend %__weather_mode;
      padding: 10px 30px;
      margin: 5px 0px 0px 5px;
      justify-self: flex-start;
      align-self: flex-start;
      color: $color_black_principal;
      background-color: $color_grey_principal;
    }
  }
}

%__styles_weather_container {
  display: grid;
  box-shadow: 1px 2px 1px 1px rgba(grey, 0.3);
  height: 300px;
  border-radius: 15px;
  grid-template-rows: 15% 38% 2% 5% 40%;
}

// @media (max-width: 1080px) {
//   ._weather_container_1 {
//     grid-column: 2;
//     grid-row: 1;
//   }

//   ._weather_container_0 {
//     grid-column: 1;
//     grid-row: 3;
//   }

//   ._weather_container_1_pluggin {
//     grid-column: 2;
//     grid-row: 3;
//   }
// }

// @media (max-width: $screen_xs) {
//   ._weather_container_1 {
//     grid-column: 1;
//     grid-row: 3;
//   }

//   ._weather_container_0 {
//     grid-column: 1;
//     grid-row: 4;
//   }
// }
