.projects_container {
    display: grid;
    margin: 20px 2% 0 2%;
    grid-template-columns: 100%;
    row-gap: 30px;
    padding-bottom: 50px;
}

.projects_pagination{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .projects_pagination_button{
        background-color:$color_green_principal;
        color: white;
        margin-right: 2%;
        margin-left: 2%;
    }
    
}


@media(max-width: 1080px){
 .projects_container {
     grid-template-columns: 100%;
 }   

}