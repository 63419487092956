._measurergeneral_container_dates_ {
  display: grid;
  width: 100%;
  margin-top: 50px;

  ._measurergeneral_container_input_ {
    display: grid;
    grid-template-columns: 100%;
    height: 150px;

    .dropdown {
      background-color: rgba($color_grey_terceary, 0.1);
      justify-self: center;
      align-self: center;
      padding: 5px 15px;
      position: relative;
      display: inline-block;

      ._inputs__date_ {
        border: none;
        border-bottom: 1px solid rgba($color_grey_principal, 0.5);
        padding: 10px 35px;
        font-size: 15px;
        margin: 15px 25px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
      }

      ._time__icon_ {
        font-size: 25px;
        padding-left: 15px;
        cursor: pointer;
        color: rgba($color_grey_four, 1);

        &:hover {
          padding-left: 15px;
          border-radius: 50%;
        }
      }

      .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        padding: 12px 16px;
        z-index: 1;

        ._measurergeneral_container_button_ {
          display: grid;
          padding: 10px 0 10px 0;
          grid-template-columns: 100%;
          height: 30px;

          ._measurergeneral_button_ {
            border: none;
            cursor: pointer;
            font-size: 15px;
            background-color: $color_blue_three;
            color: $color_white_principal;

            &:hover {
              background-color: rgba($color_blue_three, 0.7);
            }
          }
        }
      }

      &:hover .dropdown-content {
        display: block;
      }
    }
  }

  ._measurergeneral_container_tables_ {
    display: grid;
    grid-template-columns: 100%;
    margin: 50px 20% 50px 20%;

    .__report_measurer_table_total_title__ {
      font-size: 25px;
      display: grid;
      text-align: center;
      padding: 10px;
    }
  }

  .__measurergeneral_grid_title_tables__ {
    display: grid;
    grid-template-columns: 35% 30% 35%;

    hr {
      border: 0;
      border-top: 2px solid $color_green_principal;
      width: 90%;
      // border-color: $color_green_principal;
    }

    font-size: 25px;
    margin-top: 50px;
    text-align: center;
  }

  ._measurergeneral_grid_tables_ {
    /*  display: grid;
        grid-template-columns: repeat(4, 1fr); */
    display: grid;
    grid-template-columns: 63%;
    justify-content: center;
    align-items: center;
    //column-gap: 30px;
    margin: 0 2% 50px 2%;

    .__report_measurer_rooms__ {
      margin-top: 30px;

      .__report_measurer_by_room__ {
        display: grid;
        font-size: 15px;
        font-weight: bold;
        text-align: center;
        padding: 10px;
        grid-template-columns: 100%;
      }
    }
  }

  .date_vacio_report_measurer {
    text-align: center;
    margin: 150px 0 150px 0;

    span {
      font-size: 35px;
      padding: 15px 35px;
      border-radius: 7px;
      background-color: rgba($color_grey_terceary, 0.1);
    }
  }
}
