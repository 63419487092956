.project_datatable_container {
  display: grid;
  max-width: 1536px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  .project_container_header {
    display: flex;
    height: 100%;
    width: auto;
    font-size: 12px;

    h1 {
      margin-left: 3%;
    }

    span {
      font-weight: 600;
      font-size: 16;
      color: #929e9e;
    }

    .table_title_container {
      width: 50%;
    }

    .container_button_and_filter {
      display: flex;
      width: 49%;
      justify-content: flex-end;
      .content_header_project_button {
        display: flex;
        width: auto;
        align-items: center;
        justify-content: flex-end;
        padding-right: 6px;

        .project_button_agreger {
          align-items: center;
          display: flex;
          background: rgba(27, 114, 220, 0.921);
          color: #fff;
          height: 36px;
          padding: 11px 15px;
          border-radius: 5px;
          font-size: 14px;
          border: none;
          cursor: pointer;
          &:hover {
            color: rgb(213, 216, 223);
            cursor: pointer;
          }
        }
      }
    }
    .project_filter_container {
      width: 35%;
      margin-right: 2%;
      padding-top: 12.5px;
    }
    @media (max-width: 800px) {
      .table_title {
        font-size: 20px;
      }

      .container_button_and_filter {
        display: flex;
        .project_filter_container {
          width: 70%;
          padding-top: 7px;
        }
      }
      .table_title_container {
        width: 50%;
      }
    }
  }

  @media (max-width: 375px) {
    .project_container_header {
      display: flex;
      flex-direction: column;

      .table_title_container {
        justify-content: center;
        height: 45px;
        width: auto;
        .table_title {
          display: flex;
          justify-content: center;
        }
      }
      .container_button_and_filter {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
      .project_filter_container {
        margin-bottom: 6px;
        width: 60%;
      }
    }
  }

  .table_container {
    @media (max-width: 600px) {
      overflow: auto;
      font-size: 15px;
    }
  }

  .project_datatable {
    border-collapse: collapse;
    margin: 0 1.5%;
    height: auto;
    border-radius: 10px 10px 0 0;
    width: 97%;
    overflow: hidden;
    .project_container_title_table {
      // @extend %extend_content_table;
      background: rgba(31, 137, 229, 0.2);
      color: #000;
      width: 94.7%;
      height: 5em;
    }
    .project_container_table_body {
      // @extend %extend_content_table;
      align-items: center;
      border: 2px;
      background: rgba(113, 113, 113, 0.091);
      color: black;
      height: 4.5em;
      &:nth-child(even) {
        background-color: rgb(255, 255, 255);
      }
    }

    .main_project_status_container {
      text-align: center;

      .container_status_active_project {
        border: 0.2px solid #5cb85c;
        width: auto;
        padding: 2px 3px 5px;
        border-radius: 4px;
        background-color: rgba($color: #22a95c, $alpha: 0.15);
        border: 1px solid #22a95c;
        color: #22a95c;

        span {
          display: flex;
          align-items: center;
          width: 100%;
          font-weight: 500;
        }

        p {
          font-size: 12px;
          margin: 0px auto;
          color: white;
          display: flex;
          justify-self: center;
          justify-content: center;
        }
      }

      .container_status_inactive_project {
        width: auto;
        padding: 2px 3px 5px;
        border-radius: 4px;
        background-color: rgba($color_red_principal, 0.15);
        border: 1px solid $color_red_principal;
        color: $color_red_principal;
        span {
          display: flex;
          align-items: center;
          font-weight: 500;
        }
        p {
          font-size: 12px;
          margin: 0px auto;
          color: white;
          display: flex;
          justify-self: center;
          justify-content: center;
        }
      }
    }

    .project_container_img {
      font-weight: 400;
      text-align: center;

      .project_logo {
        width: 50px;
        height: 50px;
        border-radius: 10px;
      }
    }

    @media (max-width: 500px) {
      .project_container_img {
        font-weight: 400;
        text-align: center;

        .project_logo {
          width: 35px;
          height: 35px;
          border-radius: 10px;
        }
      }
    }

    .project_img {
      font-weight: bold;
      @media (max-width: 500px) {
        font-size: 15px;
      }
    }

    .project_container_name {
      font-weight: 400;
      text-align: center;
    }

    .project_name {
      font-weight: bold;
      text-align: center;
      @media (max-width: 500px) {
        font-size: 15px;
      }
    }

    .project_container_country {
      font-weight: 400;
      text-align: center;
    }

    .project_country {
      font-weight: bold;
      text-align: center;
      @media (max-width: 500px) {
        font-size: 15px;
      }
    }

    .project_container_state {
      text-align: center;
      font-weight: 400;
    }

    .project_state {
      font-weight: bold;
      text-align: center;
      @media (max-width: 500px) {
        font-size: 15px;
      }
    }

    .project_container_city {
      font-weight: 400;
      text-align: center;
    }

    .project_city {
      font-weight: bold;
      text-align: center;
      @media (max-width: 500px) {
        font-size: 15px;
      }
    }

    .project_status {
      font-weight: bold;
      text-align: center;
      @media (max-width: 500px) {
        font-size: 15px;
      }
    }

    .project_edit {
      font-weight: bold;
      text-align: center;
      @media (max-width: 500px) {
        font-size: 15px;
      }
    }

    .project_container_acctions {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      .project_edit_button {
        color: rgba(16, 81, 203, 0.805);
        justify-content: center;
        &:hover {
          color: rgba(16, 81, 203, 1);
          cursor: pointer;
        }
      }
    }
  }
}

.projects_pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80px;
  .projects_pagination_button {
    background-color: $color_green_principal;
    color: white;
    margin-right: 2%;
    margin-left: 2%;
  }
}
