._hsc_container {
  display: grid;
  box-shadow: 1px 2px 1px 1px rgba(grey, 0.3);
  border: rgba($color: $color_grey_principal, $alpha: 0.3) 1px solid;
  height: 300px;
  border-radius: 15px;
  grid-template-rows: 15% 40% 2% 18% 25%;

  ._hsc_container_title_status {
    display: grid;
    grid-template-columns: 60% 20% 20%;
    background-color: rgba($color: gray, $alpha: 0.1);
    border-radius: 15px 15px 0px 0px;

    ._hsc__title {
      justify-self: flex-start;
      align-self: center;
      font-size: 20px;
      padding-left: 5%;
    }

    ._hsc_status {
      justify-self: center;
      align-self: center;
      transform: rotate(45deg);

      .__icon_wifi_hsc {
        color: $color_green_principal;
        font-size: 20px;
      }

      .__icon_wifi_offline_hsc {
        color: $color_grey_principal;
        font-size: 20px;
      }

      .__battery {
        display: flex;
        flex-direction: row;
        align-self: center;

        .__percent {
          display: flex;
          flex-direction: column;

          align-self: center;
          font-size: 12px;
        }

        .__icon_battery_sensor {
          transform: rotate(90deg);
          color: $color_green_principal;
          margin-left: 3px;
        }
        .__icon_battery_sensor_unknown {
          transform: rotate(90deg);
          color: $color_yellow_principal;
          margin-left: 3px;
        }
        .__icon_battery_sensor_alert {
          transform: rotate(90deg);
          color: $color_red_secondary;
          margin-left: 3px;
        }
      }
    }
  }

  ._offline_hsc {
    background-color: rgba($color_red_principal, 0.3);
  }

  .__loading_hsc {
    display: grid;
    justify-self: center;
    align-self: center;
    margin-left: 45px;
    height: 100px;
    width: 100px;
  }

  ._hsc_ocupation {
    display: grid;
    grid-template-columns: 40% 60%;

    ._hsc_home_img {
      justify-self: center;
      align-self: center;
    }

    ._hsc_home_buttons {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: repeat(2, 1fr);

      ._free_container {
        display: grid;

        .__free_bg {
          justify-self: center;
          align-self: flex-end;
          margin-bottom: 5px;
          color: $color_white_principal;
          background-color: $color_green_principal;
          padding: 5px 35px;
          border-radius: 10px;
          cursor: pointer;

          &:hover {
            background-color: $color_grey_principal;
          }
        }
        .__free {
          justify-self: center;
          align-self: flex-end;
          margin-bottom: 5px;
          background-color: $color_grey_secondary;
          padding: 5px 35px;
          border-radius: 10px;
          cursor: pointer;

          &:hover {
            background-color: $color_grey_principal;
          }
        }
      }
      ._busy_container {
        display: grid;
        .__busy_bg {
          justify-self: center;
          align-self: flex-start;
          margin-top: 5px;
          color: $color_white_principal;
          background-color: $color_blue_three;
          padding: 5px 20px;
          border-radius: 10px;
          cursor: pointer;

          &:hover {
            background-color: $color_blue_three;
          }
        }
        .__busy {
          justify-self: center;
          align-self: flex-start;
          margin-top: 5px;
          background-color: $color_grey_secondary;
          padding: 5px 20px;
          border-radius: 10px;
          cursor: pointer;

          &:hover {
            background-color: $color_grey_principal;
          }
        }
      }
    }
  }

  ._loading_hsc_other {
    opacity: 0.2;
  }

  %__title {
    font-size: 15px;
    padding-left: 20px;
    justify-self: flex-start;
    align-self: center;
    color: $color_grey_principal;
    font-weight: bold;
  }

  ._hsc_status_system {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 40% 60%;

    ._hsc_status_system_title {
      @extend %__title;
    }

    %__principal_status_system {
      display: grid;
      font-size: 15px;
      border-radius: 5px;
      margin: 0px 25px;
    }

    %__principal_status_word {
      justify-self: center;
      align-self: center;
      color: $color_white_principal;
    }

    .__status_system_busy {
      @extend %__principal_status_system;
      border: 1px solid $color_blue_three;
      background-color: $color_blue_three;

      .__status_word {
        @extend %__principal_status_word;
        color: $color_white_principal;
      }

      .__status_system_undefined {
        border: 1px solid #000000;
        background-color: #fff;

        .__status_word_fail {
          justify-self: center;
          align-self: center;

          color: #000000;
          background-color: #fff;
        }
      }
    }

    .__status_system_free {
      @extend %__principal_status_system;
      border: 1px solid $color_green_secondary;
      background-color: $color_green_principal;

      .__status_word {
        @extend %__principal_status_word;
      }
    }

    // .__status_system {
    //   display: grid;
    //   font-size: 15px;
    //   border-radius: 5px;
    //   border: 1px solid $color_green_secondary;
    //   background-color: $color_green_principal;
    //   margin: 0px 25px;

    //   .__status_word {
    //     justify-self: center;
    //     align-self: center;
    //     color: $color_white_principal;
    //   }
    // }
  }

  ._hsc_mode {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 40% 60%;

    ._hsc_mode_title {
      @extend %__title;
    }

    .__mode_container {
      justify-self: center;
      align-self: flex-start;

      %__mode_item {
        padding: 3px 18px;
        font-size: 15px;
        cursor: pointer;
        background-color: $color_grey_secondary;
      }

      .__mode_item_off {
        @extend %__mode_item;

        &:hover {
          background-color: $color_grey_principal;
        }
      }

      .__mode_item_off_bg {
        @extend %__mode_item;
        background-color: $color_grey_four;
        border: 1px solid $color_grey_four;
        color: $color_white_principal;

        &:hover {
          background-color: $color_grey_principal;
        }
      }

      .__mode_item_manual {
        @extend %__mode_item;

        &:hover {
          background-color: $color_grey_principal;
        }
      }
      .__mode_item_manual_bg {
        @extend %__mode_item;
        background-color: $color_grey_four;
        border: 1px solid $color_grey_four;
        color: $color_white_principal;

        &:hover {
          background-color: $color_grey_principal;
        }
      }

      .__mode_item_auto {
        @extend %__mode_item;

        &:hover {
          background-color: $color_grey_principal;
        }
      }
      .__mode_item_auto_bg {
        @extend %__mode_item;
        background-color: $color_grey_four;
        border: 1px solid $color_grey_four;
        color: $color_white_principal;

        &:hover {
          background-color: $color_grey_principal;
        }
      }
    }
  }
}
