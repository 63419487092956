.main_container {
  .forgotpass_container {
    max-width: 1536px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    .forgotpass_image_container {
      display: flex;
      justify-content: center;
      align-content: center;
      padding-bottom: 10px;
      .forgotpass_image {
        display: flex;
        width: 100px;
        height: 100px;
        margin-top: 15px;
      }
    }
    .forgot_pass_title_container {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;

      .forgot_pass_title {
        font-size: 30px;
        font-weight: 600;
        font-family: "Montserrat", sans-serif;
      }
    }
    .forgot_pass_subtitle_container {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
      .forgot_pass_subtitle {
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        text-align: center;
        line-height: 1.5;
        margin: auto 38%;
      }
    }
    .forgot_pass_input_container {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
      .forgot_pass_input_and_title_container {
        justify-self: center;
        align-self: center;
        font-family: "Montserrat", sans-serif;
        .forgot_pass_input_title_container {
          font-size: 20px;
          font-weight: 600;
          padding-bottom: 10px;
          font-family: "Montserrat", sans-serif;
        }
        .forgot_pass_input {
          font-family: "Montserrat", sans-serif;
          border: 1px solid rgba(0, 0, 0, 0.5);
          padding: 15px 20px;
          font-size: 18px;
          width: 250px;
          border-radius: 5px;
        }
        .forgot_pass_span_required_input {
          font-size: 12px;
          color: $color_red_principal;
          margin-top: 10px;
        }
        .forgot_pass_toggle {
          font-size: 18px;
          font-family: "Montserrat", sans-serif;
          cursor: pointer;
          &:hover {
            color: $color_blue_three;
          }
        }
      }
    }
    .forgot_pass_submit_container {
      display: flex;
      justify-content: center;
      padding-bottom: 30px;
      .forgot_pass_submit {
        background: rgba(63, 153, 72, 0.8);
        border: 1px solid rgba(255, 255, 255, 0.5);
        justify-self: center;
        align-self: center;
        color: $color_white_principal;
        padding: 15px 20px;
        width: 290px;
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
    .forgot_pass_back_login_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .forgot_pass_back_login {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        a {
          cursor: pointer;
          text-decoration: none;
          font-size: 18px;
          font-weight: 600;
          color: $color_black_principal;
          &:hover {
            color: $color_blue_three;
          }
        }
      }
    }
    @media (max-width: 1280px) {
      .forgot_pass_subtitle_container {
        .forgot_pass_subtitle {
          margin: auto 34%;
        }
      }
    }
    @media (max-width: 1024px) {
      .forgot_pass_subtitle_container {
        .forgot_pass_subtitle {
          margin: auto 32%;
        }
      }
    }
    @media (max-width: 768px) {
      .forgot_pass_title_container {
        .forgot_pass_title {
          font-size: 26px;
        }
      }
      .forgot_pass_subtitle_container {
        .forgot_pass_subtitle {
          margin: auto 30%;
        }
      }
    }
    @media (max-width: 640px) {
      .forgot_pass_title_container {
        .forgot_pass_title {
          font-size: 22px;
        }
      }
      .forgot_pass_subtitle_container {
        .forgot_pass_subtitle {
          margin: auto 28%;
          font-size: 14px;
        }
      }
      .forgot_pass_input_container {
        .forgot_pass_input_and_title_container {
          .forgot_pass_input_title_container {
            font-size: 16px;
          }
          .forgot_pass_input {
            padding: 10px 10px;
            font-size: 16px;
            width: 200px;
          }
          .forgot_pass_toggle {
            font-size: 16px;
          }
        }
        padding-bottom: 10px;
      }
      .forgot_pass_submit_container {
        .forgot_pass_submit {
          padding: 10px 10px;
          font-size: 16px;
          width: 220px;
        }
        padding-bottom: 20px;
      }
      .forgot_pass_back_login_container {
        .forgot_pass_back_login {
          a {
            font-size: 16px;
          }
        }
      }
    }
    @media (max-width: 475px) {
      .forgot_pass_title_container {
        .forgot_pass_title {
          font-size: 18px;
        }
      }
      .forgot_pass_subtitle_container {
        .forgot_pass_subtitle {
          margin: auto 26%;
          font-size: 13px;
        }
      }
    }
    @media (max-width: 360px) {
      .forgot_pass_title_container {
        .forgot_pass_title {
          font-size: 14px;
        }
      }
      .forgot_pass_subtitle_container {
        .forgot_pass_subtitle {
          margin: auto 24%;
          font-size: 12px;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    height: 100vh;
  }
}
