.roles_datatable_container {
  display: grid;
  max-width: 1536px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  .roles_container_header {
    display: flex;
    height: 100%;
    width: auto;
    font-size: 12px;

    h1 {
      margin-left: 3%;
    }

    span {
      font-weight: 600;
      font-size: 16;
      color: #929e9e;
    }

    .roles_title_container {
      width: 50%;
    }

    .container_button_and_filter {
      display: flex;
      width: 49%;
      justify-content: flex-end;

      .content_header_button {
        display: flex;
        width: auto;
        align-items: center;
        justify-content: flex-end;
        padding-right: 6px;

        .button_agreger {
          align-items: center;
          display: flex;
          background: rgba(27, 114, 220, 0.921);
          color: #fff;
          height: 36px;
          padding: 11px 15px;
          border-radius: 5px;
          font-size: 14px;
          border: none;
          cursor: pointer;
          &:hover {
            color: rgb(213, 216, 223);
            cursor: pointer;
          }
        }
      }
      .role_filter_container {
        width: 35%;
        margin-right: 2%;
        padding-top: 12.5px;
      }
    }

    @media (max-width: 800px) {
      .table_title {
        font-size: 20px;
      }

      .container_button_and_filter {
        display: flex;
        .role_filter_container {
          width: 70%;
          padding-top: 8px;
        }
      }

      .roles_title_container {
        width: 50%;
      }
    }
  }

  @media (max-width: 500px) {
    .roles_container_header {
      display: flex;
      flex-direction: column;

      .roles_title_container {
        justify-content: center;
        height: 45px;
        width: auto;
        .table_title {
          display: flex;
          justify-content: center;
        }
      }
      .container_button_and_filter {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
      .role_filter_container {
        margin-bottom: 6px;
        width: 60%;
      }
    }
  }

  .roles_container_table {
    @media (max-width: 1920px) {
      overflow: auto;
      font-size: 15px;
    }

    .roles_datatable {
      border-collapse: collapse;
      margin: 0 1.5%;
      height: auto;
      border-radius: 10px 10px 0 0;
      width: 97%;
      overflow: hidden;

      .roles_table_header {
        background: rgba(31, 137, 229, 0.2);
        color: #000;
        width: 94.7%;
        height: 5em;
      }

      .roles_container_table_body {
        align-items: center;
        border: 2px;
        background: rgba(113, 113, 113, 0.091);
        color: black;
        height: 4.5em;
        &:nth-child(even) {
          background-color: rgb(255, 255, 255);
        }
      }

      .main_role_status_container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;

        .container_estado {
          border: 0.2px solid #5cb85c;
          width: auto;
          padding: 2px 3px 5px;
          border-radius: 4px;
          background-color: rgba($color: #22a95c, $alpha: 0.15);
          border: 1px solid #22a95c;
          color: #22a95c;

          span {
            display: flex;
            align-items: center;
            width: 100%;
            font-weight: 500;
          }

          p {
            font-size: 12px;
            margin: 0px auto;
            color: white;
            display: flex;
            justify-self: center;
            justify-content: center;
          }
        }

        .container_state_inactive {
          width: auto;
          padding: 2px 3px 5px;
          border-radius: 4px;
          background-color: rgba($color_red_principal, 0.15);
          border: 1px solid $color_red_principal;
          color: $color_red_principal;
          span {
            display: flex;
            align-items: center;
            font-weight: 500;
          }

          p {
            font-size: 12px;
            margin: 0px auto;
            color: white;
            display: flex;
            justify-self: center;
            justify-content: center;
          }
        }
      }

      .container_number_rol {
        font-weight: 400;
        text-align: center;
      }

      .container_avatar_rol {
        font-weight: 400;
        text-align: center;
      }

      .roles_container_acctions {
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        .edit_button {
          color: rgba(16, 81, 203, 0.805);
          justify-content: center;
          &:hover {
            color: rgba(16, 81, 203, 1);
            cursor: pointer;
          }
        }
        .disabled_button {
          color: rgba(208, 10, 10, 0.705);
          &:hover {
            color: rgba(208, 10, 10, 1);
            cursor: pointer;
          }
        }
        .enabled_button {
          color: #22a95c;
          &:hover {
            color: #22a95c;
            cursor: pointer;
          }
        }

        .avatar_color1 {
          color: rgb(224, 8, 8);
          border: solid 2px rgb(224, 8, 8);
          background-color: rgb(255, 255, 255);
        }
        .avatar_color2 {
          color: rgb(6, 218, 6);
          background-color: rgb(252, 252, 252);
          border: solid 2px rgb(6, 218, 6);
        }
      }
    }

    .name_rol {
      font-weight: 600;
    }

    .main_container_roles {
      width: 100px;
      .roles_container_acctions {
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        width: 200px;
        .edit_button {
          color: rgba(16, 81, 203, 0.805);
          justify-content: center;
          &:hover {
            color: rgba(16, 81, 203, 1);
            cursor: pointer;
          }
        }
        .disabled_button {
          color: rgba(208, 10, 10, 0.705);
          &:hover {
            color: rgba(208, 10, 10, 1);
            cursor: pointer;
          }
        }
        .enabled_button {
          color: #22a95c;
          &:hover {
            color: #22a95c;
            cursor: pointer;
          }
        }

        .avatar_color1 {
          color: rgb(224, 8, 8);
          border: solid 2px rgb(224, 8, 8);
          background-color: rgb(255, 255, 255);
        }
        .avatar_color2 {
          color: rgb(6, 218, 6);
          background-color: rgb(252, 252, 252);
          border: solid 2px rgb(6, 218, 6);
        }
      }
    }
  }
}

.roles_pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80px;
  .roles_pagination_button {
    background-color: $color_green_principal;
    color: white;
    margin-right: 2%;
    margin-left: 2%;
  }
}
