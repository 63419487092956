.devices_datatable_container {
  display: grid;
  max-width: 1536px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  overflow-x: clip;

  .devices_header_container {
    display: flex;
    height: 100%;
    width: auto;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 30px;

    .devices_title_container {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 35%;

      .table_title {
        margin-top: 5%;
        margin-left: 4%;
        margin-bottom: 1%;
      }

      .title_description {
        display: flex;
        width: 200px;
        margin-left: 19px;
        font-family: "Montserrat", sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.5;
        color: #767474;
      }
    }

    .select_input_container {
      display: flex;
      align-items: center;
      width: 60%;
      height: 65%;

      .add_device_button_container {
        display: flex;
        width: 7%;
        .add_device_button_project {
          align-items: center;
          display: flex;
          background: rgba(27, 114, 220, 0.921);
          color: #fff;
          height: 36px;
          padding: 11px 15px;
          border-radius: 5px;
          font-size: 14px;
          border: none;
          cursor: pointer;
          &:hover {
            color: rgb(213, 216, 223);
            cursor: pointer;
          }
        }
      }

      .select_container {
        display: flex;
        justify-content: flex-start;
        width: 47%;
      }

      .filter_projects_container {
        display: flex;
        justify-content: flex-end;
        width: 50%;
      }
    }
  }

  .warnings_container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120%;
    width: auto;

    .no_selected_filter {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 384px;
      width: 930px;
      background-color: #f5f3f3;
      border-radius: 18px;

      span {
        display: flex;
        align-items: center;
        font-family: "Montserrat", sans-serif;
        font-size: 25px;
        font-weight: 600;
        text-align: center;
        line-height: 1.5;
        height: 120px;
        width: 412px;
      }

      .add_device_filter_and_button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20%;
        width: 100%;
        gap: 5px;

        .add_device_button_container {
          display: flex;
          .add_device_button_project {
            align-items: center;
            display: flex;
            background: rgba(27, 114, 220, 0.921);
            color: #fff;
            height: 36px;
            padding: 11px 15px;
            border-radius: 5px;
            font-size: 14px;
            border: none;
            cursor: pointer;
            &:hover {
              color: rgb(213, 216, 223);
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .devices_header_container {
      .devices_title_container {
        align-items: center;
        width: 30%;
        .table_title {
          font-size: 20px;
        }
        .title_description {
          margin-left: -5px;
        }
      }
    }

    .warnings_container {
      .no_selected_filter {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 384px;
        width: 750px;
        background-color: #f5f3f3;
        border-radius: 18px;
      }
    }
  }

  @media (max-width: 950px) {
    .devices_header_container {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;

      .devices_title_container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25%;
        width: 100%;

        .table_title {
          display: flex;
          justify-content: center;
          margin-top: 4%;
          margin-right: 3%;
        }

        .title_description {
          display: flex;
          width: 350px;
          margin-left: 0px;
          text-align: center;
          font-family: "Montserrat", sans-serif;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.5;
          margin-bottom: 3%;
          color: #767474;
        }
      }

      .select_input_container {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 30%;
        gap: 30px;

        .filter_projects_container {
          display: flex;
          justify-content: center;
          margin-right: 3.5px;
          width: auto;
        }

        .select_container {
          display: flex;
          justify-content: center;
          width: auto;
        }
      }

      @media (max-width: 600px) {
        .select_input_container {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 10px;

          .filter_projects_container {
            display: flex;
            justify-content: center;
            width: 100%;
          }

          .select_container {
            display: flex;
            justify-content: center;
            width: 100%;
          }
        }
      }
    }

    .warnings_container {
      height: 100%;
      .no_selected_filter {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 384px;
        width: 540px;
        background-color: #f5f3f3;
        border-radius: 18px;
      }
    }
  }

  @media (max-width: 600px) {
    .devices_header_container {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      margin-top: 0%;

      .devices_title_container {
        margin-bottom: 3.5%;
        .table_title {
          margin-top: 1%;
        }
      }

      .select_input_container {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        width: 100%;
        height: 30%;
        gap: 18px;

        .filter_projects_container {
          display: flex;
          justify-content: center;
          margin-right: 0%;
          width: auto;
        }
      }
    }

    .warnings_container {
      .no_selected_filter {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 300px;
        width: 350px;
        background-color: #f5f3f3;
        border-radius: 18px;
        span {
          width: 350px;
          font-weight: 500;
          font-size: 17px;
        }
      }
    }
  }
}
