.filterproject_drawer {
  width: 30vw;
  padding: 5%;
  display: block;

  .filterproject_title {
    color: $color_green_principal;
    font-size: 20px;
    font-weight: 600;
  }

  .filterproject_item {
    margin-top: 5%;
    width: 90%;
  }

  .filterproject_button {
    margin-top: 10%;
    width: 90%;
    color: white;
    background-color: $color_green_principal;
  }
}

.filterproject_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 30px 5% 30px 5%;
  border-radius: 10px;
  box-shadow: 4px 1px 4px 1px rgba(grey, 0.3);
  border: rgba($color: $color_grey_principal, $alpha: 0.3) 1px solid;

  .filterproject_title {
    font-size: 25px;
  }

  .filterproject_filter_search_container {
    display: flex;
    align-items: center;
    justify-content: end;
    grid-template-columns: 100%;

    .filterproject_search {
      justify-self: flex-end;
      align-self: center;
      margin-left: 8%;

      .input_search {
        // box-shadow: 1px 1px 4px 4px rgba($color_grey_principal, 0.3);
        border: none;
        font-size: 16px;
        border-radius: 5px;
        padding: 10px 40px 10px 10px;
        background-color: white;
      }
    }
  }
}

@media (max-width: $screen_md) {
  .filterproject_container {
    display: flex;
    justify-content: space-evenly;
    grid-template-columns: auto 50%;
    row-gap: 15px;

    .filterproject_title {
      font-size: 20px;
      justify-self: center;
      align-self: center;
    }
  }
}

@media (max-width: $screen_xs) {
  .filterproject_container {
    display: flex;
    flex-wrap: wrap;

    .filterproject_title {
      font-size: 20px;
    }

    .filterproject_filter_search_container {
      grid-template-columns: 100%;
      row-gap: 15px;
    }
  }
}
