._weather_container_new {
  @extend %__styles_weather_container_new;
  border: rgba($color: $color_grey_principal, $alpha: 0.3) 1px solid;
  @extend %__styles_complement;
}

._weather_container_pluggin_new {
  @extend %__styles_weather_container_new;
  border: rgba($color: $color_grey_principal, $alpha: 0.3) 1px solid;
  @extend %__styles_complement;
}

%__styles_complement {
  ._weather_container_title_status_new {
    display: flex;
    justify-content: space-evenly;
    gap: 15px;
    background-color: rgba($color: gray, $alpha: 0.1);
    border-radius: 15px 15px 0px 0px;

    ._weather__title_new {
      justify-self: flex-start;
      align-self: center;
      font-size: 20px;
    }

    .__icon_offline__new {
      transform: rotate(0deg) !important;
    }

    ._weather__status_new {
      justify-self: center;
      align-self: center;
      transform: rotate(45deg);

      .__icon_wifi_online {
        color: $color_green_principal;
        font-size: 20px;
      }

      .__icon_wifi_offline {
        color: $color_grey_principal;
        font-size: 20px;
      }
    }

    .battery_status {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0px 15px;
      gap: 5px;

      .battery_percentage {
        font-size: 12px;
      }

      .battery_icon_container {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(90deg);
        cursor: pointer;

        .__icon_battery_sensor {
          display: flex;
          color: $color_green_principal;
        }
        .__icon_battery_sensor_unknown {
          display: flex;
          color: $color_yellow_principal;
        }

        .__icon_battery_half_level {
          display: flex;
          color: $color_orange_principal;
        }

        .__icon_battery_sensor_alert {
          display: flex;
          color: $color_red_secondary;
        }
      }
    }
  }

  ._weather_offline_new {
    background-color: rgba($color_red_principal, 0.3);
  }

  .__loading_weather_other_new {
    opacity: 0.2;
  }

  ._weather_container_temperature_new {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .__weather_temperatur_off_new {
      background-color: rgba($color_grey_terceary, 0.5) !important;
      border: 1px solid $color_grey_terceary !important;
    }

    ._weather_temperature_new {
      margin-top: 10px;
      margin-left: 10px;
      display: grid;
      font-size: 50px;
      justify-self: center;
      align-self: center;
      border: 1px solid $color_blue_one;
      background-color: rgba($color_blue_two, 0.3);
      border-radius: 10px;
      padding: 0px 25px 5px 1.5px;

      .__temperature_new {
        display: flex;
        font-family: $font_weather;
        font-size: 70px;
        margin-left: 40px;
        color: $color_blue_three;
      }

      .__temperatur_off_new {
        color: $color_grey_four !important;
      }
    }

    // @keyframes girarVentilador {
    //   from {
    //     transform: rotate(0deg);
    //   }
    //   to {
    //     transform: rotate(360deg);
    //   }
    // }
    .__container_fan_new {
      display: grid;
      width: 100%;
      align-content: center;
      gap: 14px;
      margin-top: 15px;
    }

    .prueba {
      border-radius: 20px;
      border: none;
      width: 45px;
      height: 45px;
      cursor: pointer;
      // animation: girarVentilador 0.5s linear infinite;
    }
    .prueba:hover,
    .prueba2:hover,
    .prueba3:hover {
      width: 45px;
      height: 45px;
      border: none;
      background-color: $color_blue_three;
      cursor: pointer;
      // animation: girarVentilador 1s linear infinite;
    }
    .prueba2 {
      border-radius: 20px;
      border: none;
      width: 45px;
      height: 45px;
      cursor: pointer;
      // animation: girarVentilador 1.5s linear infinite;
    }

    .prueba3 {
      border-radius: 20px;
      border: none;
      width: 45px;
      height: 45px;
      cursor: pointer;
      // animation: girarVentilador 3.5s linear infinite;
    }

    .fan_and_velocity_container {
      display: flex;
      flex-direction: column;
      width: 100%;

      .__color_new {
        display: flex;
        color: black;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      .icon_bar {
        display: flex;
        position: relative;
        bottom: 0;
        width: 100%;
        background-color: lightgray;
        border-radius: 2px 5px 2px 5px;
      }
      .bar {
        display: flex;
        background-color: lightgray;
        height: 15px;
        width: 50px;
      }
      .bar1 {
        display: flex;
        background-color: lightgray;
        border-radius: 5px;
        transform: translate3d(52px, 0px, 10px) rotate(-45deg);
        width: 28px;
        height: 10px;
      }
      .bar2 {
        display: flex;
        background-color: lightgray;
        border-radius: 5px;
        transform: translate3d(33px, -28px, 10px) rotate(90deg);
        width: 28px;
        height: 10px;
      }
      .bar3 {
        display: flex;
        background-color: lightgray;
        border-radius: 5px;
        transform: translate3d(-5px, -56px, 10px) rotate(45deg);
        width: 28px;
        height: 10px;
      }
      .low {
        display: flex;
        background-color: rgb(40, 110, 241);
        border-radius: 5px;
        transform: translate3d(52px, 0px, 10px) rotate(-45deg);
        width: 28px;
        height: 10px;
      }
      .medium {
        display: flex;
        background-color: rgb(40, 110, 241);
        border-radius: 5px;
        transform: translate3d(33px, -28px, 10px) rotate(90deg);
        width: 28px;
        height: 10px;
      }
      .high {
        display: flex;
        background-color: rgb(40, 110, 241);
        border-radius: 5px;
        transform: translate3d(-5px, -56px, 10px) rotate(45deg);
        width: 28px;
        height: 10px;
      }

      @keyframes moverFondo {
        0% {
          background-position: 0% 0%;
        }
        100% {
          background-position: 100% 100%;
        }
      }

      .low_now,
      .medium_now,
      .high_now {
        display: flex;
        background-size: 200% 200%; /* Aumenta el tamaño del fondo para permitir el movimiento */
        background-image: linear-gradient(
          135deg,
          rgba(40, 110, 241, 0.65) 25%,
          transparent 25%,
          transparent 50%,
          rgba(40, 110, 241, 0.65) 50%,
          rgba(40, 110, 241, 0.65) 75%,
          transparent 75%,
          transparent
        );
        height: 15px;
        width: 50px;
        // animation: moverFondo 3s infinite linear; /* Aplica la animación */
      }

      .high_now {
        border-radius: 5px;
        transform: translate3d(-5px, -56px, 10px) rotate(45deg);
        width: 28px;
        height: 10px;
      }

      .medium_now {
        border-radius: 5px;
        transform: translate3d(33px, -28px, 10px) rotate(90deg);
        width: 28px;
        height: 10px;
      }

      .low_now {
        border-radius: 5px;
        transform: translate3d(52px, 0px, 10px) rotate(-45deg);
        width: 28px;
        height: 10px;
      }

      .central_termostat_on {
        display: flex;
        background-color: rgb(40, 110, 241);
        border-radius: 8px;
        height: 15px;
        width: 150px;
        margin-top: 8px;
      }

      .central_thermostat_auto {
        display: flex;
        background-size: 200% 200%; /* Aumenta el tamaño del fondo para permitir el movimiento */
        border-radius: 8px;
        margin-top: 8px;
        background-image: linear-gradient(
          135deg,
          rgba(40, 110, 241, 0.65) 25%,
          transparent 25%,
          transparent 50%,
          rgba(40, 110, 241, 0.65) 50%,
          rgba(40, 110, 241, 0.65) 75%,
          transparent 75%,
          transparent
        );
        height: 15px;
        width: 150px;
        // animation: moverFondo 3s infinite linear; /* Aplica la animación */
      }

      // .low_now {
      //   display: flex;
      //   background-image: linear-gradient(135deg, rgba(40, 110, 241, 0.65) 25%, transparent 25%, transparent 50%, rgba(40, 110, 241, 0.65) 50%, rgba(40, 110, 241, 0.65) 75%, transparent 75%, transparent);
      //   height: 15px;
      //   width: 50px;
      // }
      // .medium_now {
      //   display: flex;
      //   background-image: linear-gradient(135deg, rgba(40, 110, 241, 0.65) 25%, transparent 25%, transparent 50%, rgba(40, 110, 241, 0.65) 50%, rgba(40, 110, 241, 0.65) 75%, transparent 75%, transparent);
      //   border-radius: 3px;
      //   left: 6px;
      //   width: 50px;
      //   height: 15px;
      // }
      // .high_now {
      //   display: flex;
      //   background-image: linear-gradient(135deg, rgba(40, 110, 241, 0.65) 25%, transparent 25%, transparent 50%, rgba(40, 110, 241, 0.65) 50%, rgba(40, 110, 241, 0.65) 75%, transparent 75%, transparent);
      //   left: 13px;
      //   width: 50px;
      //   height: 15px;
      // }
      // }
    }
  }
}

.__loading_weather_new {
  display: grid;
  justify-self: center;
  align-self: center;
  margin-left: 45px;
  z-index: -999;
  width: 100px;
  height: 100px;
}

._indoor_weather_new {
  display: grid;

  .__indoor_new {
    display: flex;
    justify-content: space-evenly;
    font-family: $font_weather;
    gap: 30px;
    margin-left: 10px;
    font-size: 20px;
    color: $color_grey_terceary;
  }
  .__indoor_new_name {
    justify-self: center;
    align-self: center;
    font-size: 14px;
    font-weight: bold;
    color: $color_grey_terceary;
  }
}

.nameFan {
  color: $color_grey_principal;
  font-size: 16px;
  font-weight: bold;
}

._weather_container_mode_new {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  // grid-template-rows: repeat(2, 1fr);
  ._weather_container_up_down_new {
    display: grid;
    width: 100%;
    justify-self: center;
    grid-template-rows: repeat(2, 1fr);

    ._weather_btn_up_down_new {
      display: grid;
      justify-self: center;
      grid-template-columns: 80% 20%;

      ._weather_temperature_new {
        font-size: 50px;
        justify-self: center;
        align-self: center;
        border: 1px solid $color_blue_one;
        background-color: rgba($color_blue_two, 0.3);
        border-radius: 10px;
        padding: 0px 35px 5px 35px;

        .__temperature_new {
          display: flex;
          font-family: $font_weather;
          font-size: 70px;
          margin-left: 20px;
          color: $color_blue_three;
        }
      }

      %__btn_up_down {
        margin-left: 10px;
        margin-right: 5px;
        font-size: 25px;
        font-family: Tahoma;
        padding: 6.5px 66px;
        border: none;
        border-radius: 10px;
        background-color: $color_grey_secondary;
        cursor: pointer;
        color: $color_grey_principal;

        &:hover {
          background-color: $color_grey_principal;
          color: $color_white_principal;
        }
      }

      .__btn_up_new {
        @extend %__btn_up_down;
        justify-self: flex-start;
        align-self: flex-end;
        margin-bottom: 5px;
      }

      .__btn_down_new {
        @extend %__btn_up_down;
        justify-self: flex-start;
        align-self: flex-start;
        margin-top: 5px;
      }
    }
  }

  .container_options_new {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  %__weather_mode {
    padding: 5px 15px;
    border-radius: 10px;
    background-color: $color_grey_secondary;
    cursor: pointer;

    &:hover {
      background-color: $color_grey_principal;
    }
  }

  ._weather_mode_cool_new {
    @extend %__weather_mode;
    padding: 10px 20px;
    margin: 0 5px 5px 0;
    justify-self: flex-end;
    align-self: flex-end;
  }

  ._weather_mode_cool_bg_new {
    @extend %__weather_mode;
    padding: 10px 20px;
    margin: 0 5px 5px 0;
    background-color: $color_blue_three;
    border: 1px solid $color_blue_three;
    color: $color_white_principal;
    justify-self: flex-end;
    align-self: flex-end;
  }

  ._weather_mode_heat_new {
    @extend %__weather_mode;
    margin: 0 0px 5px 5px;
    padding: 10px 20px;
    justify-self: flex-start;
    align-self: flex-end;
  }

  ._weather_mode_heat_bg_new {
    @extend %__weather_mode;
    margin: 0 0px 5px 5px;
    padding: 10px 20px;
    color: $color_white_principal;
    background-color: $color_red_principal;
    justify-self: flex-start;
    align-self: flex-end;
  }

  ._weather_mode_auto_new {
    @extend %__weather_mode;
    margin: 5px 5px 0 0px;
    padding: 10px 20px;
    justify-self: flex-end;
    align-self: flex-start;
  }

  ._weather_mode_auto_bg_new {
    @extend %__weather_mode;
    margin: 5px 5px 0 0px;
    padding: 10px 20px;
    justify-self: flex-end;
    align-self: flex-start;
    color: $color_white_principal;
    background-color: $color_green_principal;
  }

  ._weather_mode_off_new {
    @extend %__weather_mode;
    padding: 10px 25px;
    margin: 5px 0px 0px 5px;
    justify-self: flex-start;
    align-self: flex-start;
  }

  ._weather_mode_off_bg_new {
    @extend %__weather_mode;
    padding: 10px 25px;
    margin: 5px 0px 0px 5px;
    justify-self: flex-start;
    align-self: flex-start;
    color: $color_black_principal;
    background-color: $color_grey_principal;
  }
}

%__styles_weather_container_new {
  display: grid;
  box-shadow: 1px 2px 1px 1px rgba(grey, 0.3);
  height: 300px;
  border-radius: 15px;
  grid-template-rows: 15% 38% 0% 45%;
}

._battery__status_new {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  cursor: pointer;

  .__percent_new {
    justify-self: flex-end;
    align-self: center;
    font-size: 12px;
  }

  .__battery_new {
    justify-self: flex-start;
    color: aqua;
    align-self: center;
    transform: rotate(90deg);

    .__icon_battery_sensor_new {
      color: $color_green_principal;
      // margin-left: 3px;
    }
    .__icon_battery_sensor_unknown_new {
      color: $color_yellow_principal;
      margin-left: 3px;
    }
    .__icon_battery_sensor_alert_new {
      color: $color_red_secondary;
      margin-left: 3px;
    }
  }
}
